import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useLocation } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import LOGIN from "../../apollo/mutations/login";
import { Context as CurrentUserContext } from "../../context/currentUser";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import Input from "../elements/form/Input/Input";
import Distributor from "../structure/Distributor/Distributor";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 50px;
  width: 400px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 10px 10px 20px 0px rgb(0 0 0 / 7%);
  p {
    margin-bottom: 3rem;
  }
`;

const Login = () => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { login: loginUser } = useContext(CurrentUserContext);
  const location = useLocation();
  const { from } = location?.state || { from: { pathname: "/admin/users" } };

  const [loginMutation, { loading }] = useMutation(LOGIN, {
    onCompleted: ({ login }) => {
      // set user to context
      loginUser(login);
      setRedirectToReferrer(true);
    },
    onError: (error) => toast.error("Netačan password ili username"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = ({ username, password }) => {
    if (!username || !password) return;
    loginMutation({
      variables: {
        username,
        password,
      },
    });
  };

  // remove token if accessed login page
  useEffect(() => {
    localStorage.removeItem("user");
  }, []);

  return redirectToReferrer ? (
    <Redirect to={from} />
  ) : (
    <Wrapper>
      <h1>Login</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos
        sequi ex ut nulla quo saepe recusandae temporibus dicta
      </p>
      <form onSubmit={handleSubmit(handleLogin)}>
        <FormField>
          <Input placeholder="Username" {...register("username")} />
        </FormField>
        <FormField>
          <Input
            placeholder="Password"
            type="password"
            {...register("password")}
          />
        </FormField>
        <Distributor submitButtons stack="end">
          <Button loading={loading}>Login</Button>
        </Distributor>
      </form>
    </Wrapper>
  );
};

export default Login;
