import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const FormField = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default FormField;
