import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { Context as CurrentUserContext } from "../../../context/currentUser";
import useWindowSize from "../../../hooks/useWindowSize";
import AdminNavigation from "../Navigation/AdminNavigation";
import MobileNavigation from "../Navigation/MobileNavigation";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;
const Content = styled.div`
  flex: 1;
  padding: 40px;
`;

const NavWrapper = styled.div`
  margin: 1rem 0 0 1rem;
`;

const AdminLayout = ({ children }) => {
  const { width } = useWindowSize();
  const { path } = useRouteMatch();
  const { logout } = useContext(CurrentUserContext);
  return (
    <Wrapper>
      {width < 601 ? (
        <NavWrapper>
          <MobileNavigation
            items={[
              {
                title: "Korisnici",
                url: `${path}/users`,
              },
              {
                title: "Proizvodi",
                url: `${path}/products`,
              },
              {
                title: "Ponude",
                url: `${path}/offers`,
              },
              {
                title: "Narudžbe",
                url: `${path}/orders`,
              },
              {
                title: "Mapa",
                url: `${path}/map`,
              },
              {
                title: "Cjenovnici",
                url: `${path}/price-lists`,
              },
              {
                title: "Logout",
                url: `#`,
                click: logout,
              },
            ]}
          />
        </NavWrapper>
      ) : (
        <AdminNavigation />
      )}
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default AdminLayout;
