import React, { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import styled from "styled-components";
import Button from "../../../components/elements/form/Button/Button";
import CreateTruck from "../../../components/forms/CreateTruck.form";
import TopControls from "../../../components/structure/TopControls/TopControls";
import { ModalContext } from "../../../context/modalContext";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import { useQuery } from "@apollo/client";
import GET_TRUCKS from "../../../apollo/queries/getTrucks";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";

const Wrapper = styled.div``;

const TrucksTable = () => {
  const { handleModal } = useContext(ModalContext);
  const { data: { getTrucks } = {}, loading } = useQuery(GET_TRUCKS);

  return (
    <Wrapper>
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          onClick={() => handleModal(<CreateTruck />)}
          icon={FaPlus}
        >
          Dodaj kamion
        </Button>
      </TopControls>
      <Table loading={loading}>
        <TableHead>
          <TableRow>
            <TableHeader>Kamion</TableHeader>
            <TableHeader>Tablice</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getTrucks?.map((truck) => (
            <TableRow key={truck._id}>
              <TableCell>{truck.truck}</TableCell>
              <TableCell>{truck.plates}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={truck._id}
                  actions={[
                    {
                      label: "Obriši kamion",
                      action: () => alert("Brišem kamion..."),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default TrucksTable;
