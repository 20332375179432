import React from "react";
import styled, { useTheme } from "styled-components";
import ScaleLoader from "react-spinners/ScaleLoader";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  ${({ size }) =>
    size
      ? `
    height: ${size}
  `
      : "300px"};
`;

const Spinner = ({ height, width, size, color }) => {
  const theme = useTheme();
  return (
    <Wrapper size={size}>
      <ScaleLoader
        color={color || theme.colors.red}
        height={height || 20}
        radius={20}
        width={width || 5}
      />
    </Wrapper>
  );
};

export default Spinner;
