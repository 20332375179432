import gql from "graphql-tag";

const SEARCH_PRODUCTS = gql`
  query searchProducts($query: String, $category: ID) {
    searchProducts(query: $query, category: $category) {
      _id
      name
      inventoryTag
      attributes {
        thickness
        dimensions {
          sheetWidth
          sheetHeight
        }
      }
      price {
        regularPrice
        casePrice
      }
      category {
        name
        image
      }
    }
  }
`;

export default SEARCH_PRODUCTS;
