import { useMutation } from "@apollo/client";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaCaretLeft, FaSave } from "react-icons/fa";
import { Prompt, useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import SAVE_PRICE_LIST from "../../../apollo/mutations/savePriceList";
import Button from "../../../components/elements/form/Button/Button";
import IconText from "../../../components/elements/IconText/IconText";
import Container from "../../../components/structure/Container/Container";
import TopControls from "../../../components/structure/TopControls/TopControls";
import PricesTable from "./PricesTable";

const Wrapper = styled.div``;

const NewPriceList = () => {
  const methods = useForm();
  const history = useHistory();
  const { priceListId } = useParams();

  const [savePriceListMutation] = useMutation(SAVE_PRICE_LIST, {
    onError: () => toast.error("Nemoguće snimiti cjenovnik"),
    onCompleted: () => {
      toast.success("Cjenovnik uspješno snimljen");
      history.push("/admin/price-lists");
    },
    fetchPolicy: "network-only",
  });

  const handleSavePriceList = () => {
    const values = methods.getValues();
    const parsedValues = Object.keys(values).map((key) => {
      return {
        product: key,
        ...values[key],
      };
    });
    // check if user is editing or creating new one
    if (priceListId) {
      // just update price list
      savePriceListMutation({
        variables: {
          priceListId,
          prices: parsedValues,
        },
        refetchQueries: ["getPriceLists"],
      });
    } else {
      const name = prompt("Unesi naziv cjenovnika");
      if (name) {
        savePriceListMutation({
          variables: {
            name,
            prices: parsedValues,
          },
          refetchQueries: ["getPriceLists"],
        });
      }
    }
  };

  return (
    <Wrapper>
      <Prompt
        when={true}
        message="Da li ste sigurni da želite da prekinete kreiranje cjenovnika?"
      />
      <IconText
        onClick={history.goBack}
        text="Idi nazad"
        icon={FaCaretLeft}
        hoverable
      />
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          icon={FaSave}
          onClick={handleSavePriceList}
        >
          Sačuvaj cjenovnik
        </Button>
      </TopControls>
      <Container size="lg">
        <FormProvider {...methods}>
          <PricesTable />
        </FormProvider>
      </Container>
    </Wrapper>
  );
};

export default NewPriceList;
