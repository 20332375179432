import React, { useContext } from "react";
import { Prompt } from "react-router";
import styled from "styled-components";
import { Context as OfferContext } from "../../../context/offerContext";
import { price } from "../../../helpers/various";
import ButtonDropdown from "../ButtonDropdown/ButtonDropdown";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../Table/Table";

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const OfferCart = () => {
  const {
    state: { offerCart },
    removeFromOffer,
  } = useContext(OfferContext);

  return offerCart?.length ? (
    <Wrapper>
      <Prompt
        when={offerCart?.length > 0}
        message="Da li ste sigurni da želite da prekinete kreiranje ponude?"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Proizvod</TableHeader>
            <TableHeader>Dimenzija</TableHeader>
            <TableHeader>Količina</TableHeader>
            <TableHeader>Cijena</TableHeader>
            <TableHeader>Kvadratura</TableHeader>
            <TableHeader>Ukupno</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {offerCart.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.data.name}</TableCell>
              <TableCell>{item.dimension}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{item.surface.toFixed(2)}</TableCell>
              <TableCell>{price(item.totalPrice)}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={item.id}
                  actions={[
                    {
                      label: "Ukloni",
                      action: () => removeFromOffer(item.id),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  ) : null;
};

export default OfferCart;
