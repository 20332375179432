import React from "react";
import styled from "styled-components";
import Spinner from "../../../components/elements/Spinner/Spinner";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import { orderNumber, price } from "../../../helpers/various";
import OfferHeader from "./OfferHeader";

const Wrapper = styled.div``;

const Title = styled.div`
  h2,
  p {
    margin: 0;
    padding: 0;
  }
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OfferPreview = ({ offer }) => {
  console.log(offer);
  return offer ? (
    <Wrapper>
      <OfferHeader />
      <Title>
        <h2>Informativna ponuda</h2>
        <p>Br. {orderNumber(offer.orderNumber)}</p>
      </Title>
      <Table>
        <TableHead>
          <TableHeader>Proizvod</TableHeader>
          <TableHeader>Dimenzija</TableHeader>
          <TableHeader>Količina</TableHeader>
          <TableHeader>Površina</TableHeader>
          <TableHeader>Cijena</TableHeader>
          <TableHeader>Ukupno</TableHeader>
        </TableHead>
        <TableBody>
          {offer.cart.map((cartItem, i) => (
            <TableRow key={i}>
              <TableCell>{cartItem.product.name}</TableCell>
              <TableCell>
                {cartItem.dimension.sheetWidth}x{cartItem.dimension.sheetHeight}
              </TableCell>
              <TableCell>{cartItem.quantity}</TableCell>
              <TableCell>{cartItem.surface}</TableCell>
              <TableCell>{price(cartItem.price)}</TableCell>
              <TableCell>{price(cartItem.totalPrice)}</TableCell>
            </TableRow>
          ))}
          <TableRow borderTop="2px" noStripe>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell tinted>Ukupno bez PDV-a</TableCell>
            <TableCell tinted>
              <strong>{price(offer.priceToPay.withoutTax)}</strong>
            </TableCell>
          </TableRow>
          <TableRow noStripe>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell tinted>Ukupno sa PDV-om</TableCell>
            <TableCell tinted>
              <strong>{price(offer.priceToPay.withTax)}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Wrapper>
  ) : (
    <Spinner />
  );
};

export default OfferPreview;
