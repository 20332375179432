import React from "react";
import styled from "styled-components";
import loginBg from "../assets/static/login-bg.jpg";
import Login from "../components/forms/Login.form";
import Logo from "../components/structure/Logo/Logo";

const Left = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Right = styled.div`
  background-image: url(${loginBg});
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
`;

const LogoWrapper = styled.div``;
const LoginFormWrapper = styled.div`
  position: absolute;
  top: 30%;
  flex: 1;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @media screen and (max-width: 601px) {
    ${Right} {
      display: none;
    }
    ${Left} {
      padding: 0;
      flex: 1;
    }
    ${LogoWrapper} {
      display: flex;
      justify-content: center;
      padding: 3rem;
      position: static;
      height: auto;
    }
    ${LoginFormWrapper} {
      padding: 0;
      display: flex;
      width: 100vw;
      > div {
        padding: 0;
        width: 100%;
        box-shadow: none;
        padding: 2rem;
        p {
          display: none;
        }
      }
    }
  }
`;

const LoginScreen = () => {
  return (
    <Wrapper>
      <Left>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <LoginFormWrapper>
          <Login />
        </LoginFormWrapper>
      </Left>
      <Right />
    </Wrapper>
  );
};

export default LoginScreen;
