import createDataContext from "./createDataContext";

const currentUserReducer = (state, action) => {
  switch (action.type) {
    case "logout":
      localStorage.removeItem("user");
      return { currentUser: {}, authorized: false };
    case "login":
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, authorized: true, currentUser: action.payload.user };
    case "reauthenticate":
      // check if token exists
      const token = JSON.parse(localStorage.getItem("user"));
      if (!token) return state;
      return { ...state, authorized: true, currentUser: token.user };
    default:
      return state;
  }
};

const logout = (dispatch) => () => dispatch({ type: "logout" });
const login = (dispatch) => (userData) =>
  dispatch({ type: "login", payload: userData });
const reauthenticate = (dispatch) => () => dispatch({ type: "reauthenticate" });

export const { Context, Provider } = createDataContext(
  currentUserReducer,
  {
    login,
    logout,
    reauthenticate,
  },
  { currentUser: {}, authorized: false }
);
