import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import UPDATE_SINGLE_PRICE from "../../../apollo/mutations/updateSinglePrice";
import { ModalContext } from "../../../context/modalContext";
import Button from "../../elements/form/Button/Button";
import FormField from "../../elements/form/FormField/FormField";
import FormHeader from "../../elements/form/FormHeader/FormHeader";
import Input from "../../elements/form/Input/Input";
import Label from "../../elements/form/Label/Label";
import Distributor from "../../structure/Distributor/Distributor";
import { userPriceSchema } from "../schema";

const EditUserPrice = ({ data }) => {
  const { handleModal } = useContext(ModalContext);
  const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(userPriceSchema),
  });

  const [updatePriceMutation, { loading }] = useMutation(UPDATE_SINGLE_PRICE, {
    onCompleted: () => {
      toast.success("Cijena ažurirana");
      handleModal();
    },
    onError: () => toast.error("Cijenu nemoguće ažurirati"),
  });

  useEffect(() => {
    if (data) {
      reset({
        regularPrice: data.price.regularPrice,
        casePrice: data.price.casePrice,
      });
    }
  }, [data, reset]);

  const onSubmitHandler = (values) => {
    updatePriceMutation({
      variables: {
        priceId: data._id,
        ...values,
      },
      refetchQueries: ["getUserPrices"],
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormHeader>{data.product.name}</FormHeader>
      <FormField>
        <Label htmlFor="regularPrice">Cijena ploče</Label>
        <Input {...register("regularPrice", { valueAsNumber: true })} />
      </FormField>
      <FormField>
        <Label htmlFor="casePrice">Cijena palete</Label>
        <Input {...register("casePrice", { valueAsNumber: true })} />
      </FormField>
      <Distributor submitButtons stack="end">
        <Button layout="round">Spremi cijenu</Button>
      </Distributor>
    </form>
  );
};

export default EditUserPrice;
