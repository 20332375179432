/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { roles } from "../../constants/constants";

yup.setLocale({
  mixed: {
    required: "Neophodno polje",
    notType: "Neispravan unos",
  },
  string: {
    min: "Minimalno ${min} znakova",
    matches: "Nedozvoljeni znakovi",
  },
});

const updateUserSchema = yup.object().shape({
  username: yup.string().required(),
  fullName: yup.string().required(),
  company: yup.string().required(),
  role: yup.string().oneOf(roles.map((r) => r.value)),
  // contact info
  contactInfo: yup.object().shape({
    email: yup.string().email().required(),
    phoneNumber: yup.string().nullable(),
    address: yup.object().shape({
      location: yup.string().nullable(),
      geo_lon: yup.string().nullable(),
      geo_lat: yup.string().nullable(),
    }),
  }),
  creditLimit: yup.object().shape({
    approvedLimit: yup.number().nullable(),
    paymentTerm: yup.number().nullable(),
    debt: yup.number().nullable(),
  }),
});

const createUserSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
  email: yup.string().email().required(),
  fullName: yup.string().required(),
  company: yup.string(),
  role: yup.string().oneOf(roles.map((r) => r.value)),
});

const createProductSchema = yup.object().shape({
  name: yup.string().required(),
  thickness: yup.number().required(),
  image: yup.string(),
  category: yup.string().required(),
  inventoryTag: yup.string().required(),
  price: yup.object().shape({
    regularPrice: yup.number().required(),
    casePrice: yup.number().required(),
  }),
});

const userPriceSchema = yup.object().shape({
  regularPrice: yup.number().required(),
  casePrice: yup.number().required(),
});

const changePasswordSchema = yup.object().shape();

const productDimensionSchema = yup.object().shape({
  sheetWidth: yup.number().required(),
  sheetHeight: yup.number().required(),
  stockQuantity: yup.number(),
  lowStockLevelIndicator: yup.number().required(),
});

const productCategorySchema = yup.object().shape({
  name: yup.string().required(),
  image: yup.string(),
});

const sendGuestOrderSchema = yup.object().shape({
  user: yup.object().shape({
    fullName: yup.string().required().max(40),
    phoneNumber: yup.string().required().max(40),
  }),
  note: yup.string().max(1000),
});

const sendToInvoicingSchema = yup.object().shape({
  driver: yup.string().required(),
  truck: yup.string().required(),
  invoiceDate: yup.date().required(),
  paymentMethod: yup.string().required(),
  sentToInvoicing: yup.object().shape({
    note: yup.string(),
  }),
});

const sendToWarehouseSchema = yup.object().shape({
  driver: yup.string().required(),
  truck: yup.string().required(),
  deliveryDate: yup.date().required(),
  paymentMethod: yup.string().required(),
  sentToWarehouse: yup.object().shape({
    note: yup.string(),
  }),
});

const sendToInvoicingAndWarehouseSchema = yup.object().shape({
  driver: yup.string().required(),
  truck: yup.string().required(),
  invoiceDate: yup.date().required(),
  deliveryDate: yup.date().required(),
  paymentMethod: yup.string().required(),
  sentToInvoicing: yup.object().shape({
    note: yup.string(),
  }),
  sentToWarehouse: yup.object().shape({
    note: yup.string(),
  }),
});

export {
  updateUserSchema,
  createUserSchema,
  changePasswordSchema,
  createProductSchema,
  userPriceSchema,
  productDimensionSchema,
  productCategorySchema,
  sendGuestOrderSchema,
  sendToInvoicingSchema,
  sendToWarehouseSchema,
  sendToInvoicingAndWarehouseSchema,
};
