import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  ${({ size }) => size && `font-size: ${size}`};
  display: inline-flex;
  align-items: center;
  :hover {
    ${({ link, url, hoverable, theme }) =>
      (link || hoverable || url) &&
      `
    cursor: pointer;
    svg {
        color: ${theme.colors.darkBlue};
    };
    `};
  }
`;

const Icon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  ${({ iconColor }) => iconColor && `color: ${iconColor}`};
`;

const Text = styled.div``;
const Counter = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 10px;
  font-weight: 700;
  top: -10px;
  left: 15px;
  border: 2px solid ${({ theme }) => theme.colors.white};
`;

const IconText = ({
  counter,
  icon,
  iconSize,
  text,
  iconColor,
  link,
  url,
  size,
  ...otherProps
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (link) {
      history.push(link);
    } else {
      const [type] = url.split(":");
      if (type === "mailto") window.open(url);
      if (type === "tel") window.open(url, "_parent");
    }
  };

  return (
    <Wrapper
      size={size}
      onClick={(link || url) && handleClick}
      link={link}
      url={url}
      {...otherProps}
    >
      <Icon iconColor={iconColor}>
        {React.createElement(icon, { size: iconSize || size || "20px" })}
        {counter ? <Counter>{counter}</Counter> : null}
      </Icon>
      <Text>{text}</Text>
    </Wrapper>
  );
};

export default IconText;
