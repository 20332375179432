import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import GET_STOCK from "../../../apollo/queries/getStock";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import Button from "../../../components/elements/form/Button/Button";
import Input from "../../../components/elements/form/Input/Input";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import CreateDimension from "../../../components/forms/CreateDimension.form";
import Container from "../../../components/structure/Container/Container";
import TopControls from "../../../components/structure/TopControls/TopControls";
import { ModalContext } from "../../../context/modalContext";
import QRCode from "../ProductDetails/QRCode";

const Search = styled.div`
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: 1rem;
  }
`;

const StockList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");

  const { handleModal } = useContext(ModalContext);

  const { data: { getStock } = {} } = useQuery(GET_STOCK);
  const { register, getValues } = useForm();

  useEffect(() => {
    setLoading(true);
    if (getStock) {
      const filteredProducts = sortBy(
        getStock.filter(
          (product) =>
            product.name.toLowerCase().includes(filter.toLowerCase()) ||
            product?.inventoryTag?.toLowerCase()?.includes(filter.toLowerCase())
        ),
        "sortingIndex"
      );
      setProducts(filteredProducts);
      setLoading(false);
    }
  }, [getStock, filter]);

  const handleSearch = () => {
    const filterString = getValues("filter");
    setFilter(filterString);
  };

  return (
    <Container>
      <TopControls>
        <Search>
          <Input
            placeholder="Unesi pojam pretrage ..."
            {...register("filter")}
          />
          <Button layout="round" predefinedSize="small" onClick={handleSearch}>
            Pretraga
          </Button>
        </Search>
      </TopControls>
      <Table loading={loading}>
        <TableHead>
          <TableRow>
            <TableHeader></TableHeader>
            <TableHeader>Proizvod</TableHeader>
            <TableHeader>Oznaka</TableHeader>
            <TableHeader>Dužina</TableHeader>
            <TableHeader>Visina</TableHeader>
            <TableHeader>Količina</TableHeader>
            <TableHeader>Granica za dopunu lagera</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, i) => (
            <TableRow key={i}>
              <TableCell>{product.sortingIndex}.</TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.inventoryTag}</TableCell>
              <TableCell>{product.dimensions.sheetWidth}</TableCell>
              <TableCell>{product.dimensions.sheetHeight}</TableCell>
              <TableCell>{product.dimensions.stockQuantity}</TableCell>
              <TableCell>{product.dimensions.lowStockLevelIndicator}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={product.dimensions._id}
                  actions={[
                    {
                      label: "Izmijeni",
                      action: () =>
                        handleModal(
                          <CreateDimension
                            productId={product._id}
                            populateFields={product.dimensions}
                          />
                        ),
                    },
                    {
                      label: "Generiši QR kod",
                      action: () =>
                        handleModal(
                          <QRCode
                            data={{
                              dimensionId: product.dimensions._id,
                              sheetWidth: product.dimensions.sheetWidth,
                              sheetHeight: product.dimensions.sheetHeight,
                              glassId: product._id,
                            }}
                          />
                        ),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default StockList;
