import React from "react";
import styled from "styled-components";
import logo from "../../../assets/static/logo.svg";
import Space from "../../../components/structure/Space/Space";
import Socials from "../../../components/structure/TopMenu/Socials";

const Wrapper = styled.div`
  width: 1000px;
`;
const Top = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    height: 70px;
  }
`;

const OfferHeader = () => {
  return (
    <Wrapper>
      <Top>
        <Socials />
        <Space vertical="2rem" />
        <img src={logo} alt="Imperial Glass Logo" />
        <Space vertical="5rem" />
      </Top>
    </Wrapper>
  );
};

export default OfferHeader;
