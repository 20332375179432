import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import styled from "styled-components";
import GET_DRIVERS from "../../../apollo/queries/getDrivers";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import Button from "../../../components/elements/form/Button/Button";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import CreateDriver from "../../../components/forms/CreateDriver.form";
import TopControls from "../../../components/structure/TopControls/TopControls";
import { ModalContext } from "../../../context/modalContext";

const Wrapper = styled.div``;

const DriversTable = () => {
  const { handleModal } = useContext(ModalContext);
  const { data: { getDrivers } = {}, loading } = useQuery(GET_DRIVERS);

  return (
    <Wrapper>
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          onClick={() => handleModal(<CreateDriver />)}
          icon={FaPlus}
        >
          Dodaj vozača
        </Button>
      </TopControls>
      <Table loading={loading}>
        <TableHead>
          <TableRow>
            <TableHeader>Ime</TableHeader>
            <TableHeader>Prezime</TableHeader>
            <TableHeader>Broj telefona</TableHeader>
            <TableHeader>Adresa</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getDrivers?.map((driver) => (
            <TableRow key={driver._id}>
              <TableCell>{driver.firstName}</TableCell>
              <TableCell>{driver.lastName}</TableCell>
              <TableCell>{driver.phoneNumber}</TableCell>
              <TableCell>{driver.address}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={driver._id}
                  actions={[
                    {
                      label: "Obriši vozača",
                      action: () => alert("Brišem vozača..."),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default DriversTable;
