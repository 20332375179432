import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import GET_PRICE_LIST from "../../../apollo/queries/getPriceList";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import Input from "../../../components/elements/form/Input/Input";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import AddNewProduct from "./AddNewProduct";
import Space from "../../../components/structure/Space/Space";
import { toast } from "react-toastify";
import { priceWithoutCurrency } from "../../../helpers/various";

const PricesTable = () => {
  const [data, setData] = useState([]);
  const [priceTableName, setPriceTableName] = useState(null);

  const { register, setValue, unregister } = useFormContext() || {};
  const { priceListId } = useParams();
  const [getPriceListQuery, { loading }] = useLazyQuery(GET_PRICE_LIST, {
    onCompleted: ({ getPriceList }) => {
      setPriceTableName(getPriceList?.name);
      setData(() => {
        getPriceList?.prices?.forEach(
          ({ product, regularPrice, casePrice }) => {
            setValue(
              `${product._id}.regularPrice`,
              priceWithoutCurrency(regularPrice)
            );
            setValue(
              `${product._id}.casePrice`,
              priceWithoutCurrency(casePrice)
            );
          }
        );
        return getPriceList?.prices;
      });
    },
  });

  const handleAddProduct = (product) => {
    const {
      _id,
      name,
      price: { casePrice, regularPrice },
    } = product;

    if (data.findIndex((p) => p.product._id === _id) !== -1)
      return toast.error("Proizvod je već u listi");

    setData([
      ...data,
      {
        product: {
          _id,
          name,
          regularPrice,
          casePrice,
        },
      },
    ]);
    setValue(`${product._id}.regularPrice`, priceWithoutCurrency(regularPrice));
    setValue(`${product._id}.casePrice`, priceWithoutCurrency(casePrice));
  };

  useEffect(() => {
    if (priceListId) {
      getPriceListQuery({
        variables: {
          priceListId,
        },
      });
    }
  }, [priceListId]);

  const handleRemoveProduct = (productId) => {
    setData(data.filter(({ product }) => product._id !== productId));
    unregister(`${productId}.regularPrice`);
    unregister(`${productId}.casePrice`);
  };

  return (
    <>
      {priceTableName && (
        <p>
          Uređuje se cjenovnik pod nazivom: <strong>{priceTableName}</strong>
        </p>
      )}
      <Space vertical="2rem" />
      <AddNewProduct onAdd={handleAddProduct} />
      <Table loading={loading}>
        <TableHead>
          <TableRow>
            <TableHeader>Naziv proizvoda</TableHeader>
            <TableHeader>Paleta</TableHeader>
            <TableHeader>Ploča</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(({ product }) => (
            <TableRow key={product._id}>
              <TableCell>{product?.name}</TableCell>
              <TableCell>
                <Input
                  type="number"
                  step="0.01"
                  min="0.01"
                  {...register(`${product._id}.casePrice`, {
                    valueAsNumber: true,
                  })}
                />
              </TableCell>
              <TableCell>
                <Input
                  type="number"
                  step="0.01"
                  min="0.01"
                  {...register(`${product._id}.regularPrice`, {
                    valueAsNumber: true,
                  })}
                />
              </TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={product._id}
                  actions={[
                    {
                      label: "Ukloni s liste",
                      action: () => handleRemoveProduct(product._id),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default PricesTable;
