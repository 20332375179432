import React from "react";
import styled from "styled-components";
import CartItem from "./CartItem";

const Wrapper = styled.div`
  flex: 1;
  > div:not(:last-child) {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.almostWhite};
  }
`;

const CartItems = ({ data }) => {
  return (
    <Wrapper>
      {data.length
        ? data?.map((item) => (
            <CartItem
              key={`${item.data._id}_${item.dimension}`}
              data={item}
              removeId={`${item.data._id}_${item.dimension}`}
            />
          ))
        : "Ništa nije dodano u korpu"}
    </Wrapper>
  );
};

export default CartItems;
