import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  input {
    ${({ icon }) => icon && `padding-left: 5rem;`};
    ${({ selected, theme }) =>
      selected &&
      `
      background-color: ${theme.colors.blue};
      color: ${theme.colors.white};
    `}
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 1.7em;
  color: ${({ theme }) => theme.colors.lightGrey};
`;

const CounterWrapper = styled.div`
  position: absolute;
  top: 36%;
  right: 1.7em;
  color: ${({ theme }) => theme.colors.lightGrey};
  @media screen and (max-width: 600px) {
    top: 9%;
    right: 10px;
    font-size: 0.8rem;
  }
`;

const Input = React.forwardRef(
  ({ icon, counter, selectedField, ...otherProps }, ref) => {
    return (
      <Wrapper
        icon={icon}
        selected={selectedField && selectedField === otherProps.name}
      >
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <input {...otherProps} ref={ref} />
        {counter ? (
          <CounterWrapper>{`${counter} rezultat/a`}</CounterWrapper>
        ) : null}
      </Wrapper>
    );
  }
);

export default Input;
