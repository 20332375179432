import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import styled from "styled-components";
import GET_PRODUCT_CATEGORIES from "../../../apollo/queries/getProductCategories";
import SEARCH_PRODUCTS from "../../../apollo/queries/searchProducts";
import Distributor from "../../structure/Distributor/Distributor";
import Button from "../form/Button/Button";
import Input from "../form/Input/Input";
import Select from "../form/Select/Select";
import SearchResults from "./SearchResults";

const Wrapper = styled.div`
  position: relative;
  top: -40px;
`;

const InputsWrapper = styled.div`
  input,
  select,
  button {
    font-size: 1.5rem;
    box-shadow: 0 10px 20px 0px rgb(0 0 0 / 6%);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  input,
  select {
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: 300;
  }
`;

const SearchInput = () => {
  const { register, getValues } = useForm();

  const { loadingCategories, data: { getProductCategories: categories } = {} } =
    useQuery(GET_PRODUCT_CATEGORIES);

  const [searchProducts, { data: { searchProducts: data } = {}, loading }] =
    useLazyQuery(SEARCH_PRODUCTS);

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = () => {
    searchProducts({
      variables: {
        query: getValues("query"),
        category: getValues("category"),
      },
    });
  };

  return (
    <Wrapper>
      <InputsWrapper>
        <Distributor col="6-2-2">
          <Input
            counter={data?.length}
            icon={<FaSearch size="30" />}
            placeholder="Unesi pojam pretrage..."
            {...register("query")}
          />
          <Select
            {...register("category")}
            label="Kategorija"
            loading={loadingCategories}
          >
            {categories?.map((cat) => (
              <option key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
          </Select>
          <Button width="100%" onClick={handleSearch}>
            Pretraga
          </Button>
        </Distributor>
      </InputsWrapper>
      <SearchResults loading={loading} data={data} />
    </Wrapper>
  );
};

export default SearchInput;
