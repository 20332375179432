import gql from "graphql-tag";

const GET_PRODUCT_CATEGORIES = gql`
  query getProductCategories {
    getProductCategories {
      _id
      name
      image
    }
  }
`;

export default GET_PRODUCT_CATEGORIES;
