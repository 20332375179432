import { useState } from "react";

const useModal = () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("Content here...");

  const handleModal = (content = false) => {
    setModal(!modal);
    content && setModalContent(content);
  };
  return { modal, handleModal, modalContent };
};

export default useModal;
