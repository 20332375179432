import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div``;
const ToggleMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 14%);
  padding: 1rem;
`;

const MenuItem = styled(NavLink)`
  padding: 1rem;
  :hover {
    background-color: ${({ theme }) => theme.colors.albino};
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.colors.white};
  z-index: 999;
  padding: 2rem;
  box-shadow: 0 -10px 20px 20px rgb(36 36 36 / 17%);
  ${({ visible }) =>
    visible
      ? `
      opacity: 1;
    transform:translateX(0);
    visibility: visible;
    `
      : `
      opacity: 0;
    transform:translateX(100%);
    visibility: hidden;
    `};

  ${MenuItem}:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.almostAlbino};
  }
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.lightGrey};
  svg {
    :hover {
      color: ${({ theme }) => theme.colors.black};
      cursor: pointer;
    }
  }
`;

const CloseMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;

const CloseMenu = ({ toggle, visible }) => {
  const hideMenu = () => {
    toggle(!visible);
  };

  return (
    <CloseMenuWrapper>
      <Close onClick={hideMenu}>
        <FaTimes size="20px" />
      </Close>
    </CloseMenuWrapper>
  );
};

const MobileNavigation = ({ items }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMenuClick = (onClick) => {
    setMenuVisible(false);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Wrapper>
      <ToggleMenu onClick={() => setMenuVisible(true)}>
        <GiHamburgerMenu size="20px" />
      </ToggleMenu>
      <Menu visible={menuVisible}>
        <CloseMenu toggle={setMenuVisible} visible={menuVisible} />
        {items?.map((item) => (
          <MenuItem to={item.url} onClick={() => handleMenuClick(item.click)}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
};

export default MobileNavigation;
