import gql from "graphql-tag";

const SEND_TO_INVOICING = gql`
  mutation sendToInvoicing(
    $offerId: ID!
    $orderInfo: OrderInfoInput
    $toWarehouse: Boolean
    $toEveryone: Boolean
  ) {
    sendToInvoicing(
      offerId: $offerId
      orderInfo: $orderInfo
      toWarehouse: $toWarehouse
      toEveryone: $toEveryone
    )
  }
`;

export default SEND_TO_INVOICING;
