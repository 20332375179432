import React from "react";
import { Switch, useRouteMatch } from "react-router";
import ProtectedRoute from "../components/security/ProtectedRoute/ProtectedRoute";
import Logistics from "../pages/admin/Logistics";
import Map from "../pages/admin/Map";
import Notifications from "../pages/admin/Notifications.page";
import Offers from "../pages/admin/Offers.page";
import NewOffer from "../pages/admin/Offers/NewOffer";
import Orders from "../pages/admin/Orders.page";
import PriceLists from "../pages/admin/PriceLists";
import NewPriceList from "../pages/admin/PriceLists/NewPriceList";
import ProductDetails from "../pages/admin/ProductDetails.page";
import Products from "../pages/admin/Products.page";
import Stock from "../pages/admin/Stock.page.js";
import UserDetails from "../pages/admin/UserDetails.page";
import Users from "../pages/admin/Users.page";

const AdminRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/products`} component={Products} />
      <ProtectedRoute
        exact
        path={`${path}/products/:productId`}
        component={ProductDetails}
      />
      <ProtectedRoute exact path={`${path}/users`} component={Users} />
      <ProtectedRoute
        exact
        path={`${path}/users/:userId`}
        component={UserDetails}
      />
      <ProtectedRoute exact path={`${path}/map`} component={Map} />
      <ProtectedRoute exact path={`${path}/offers`} component={Offers} />
      <ProtectedRoute exact path={`${path}/offers/new`} component={NewOffer} />
      <ProtectedRoute exact path={`${path}/orders`} component={Orders} />
      <ProtectedRoute
        exact
        path={`${path}/price-lists`}
        component={PriceLists}
      />
      <ProtectedRoute
        exact
        path={`${path}/price-lists/new`}
        component={NewPriceList}
      />
      <ProtectedRoute
        exact
        path={`${path}/price-lists/edit/:priceListId`}
        component={NewPriceList}
      />
      <ProtectedRoute exact path={`${path}/stock`} component={Stock} />
      <ProtectedRoute exact path={`${path}/logistics`} component={Logistics} />
      <ProtectedRoute
        exact
        path={`${path}/notifications`}
        component={Notifications}
      />
    </Switch>
  );
};

export default AdminRoutes;
