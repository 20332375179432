import { useQuery } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import GET_PRODUCTS from "../../../apollo/queries/getProducts";
import AutosuggestInput from "../../../components/AutosuggestInput/AutosuggestInput";
import FormField from "../../../components/elements/form/FormField/FormField";
import Label from "../../../components/elements/form/Label/Label";

const Wrapper = styled.div``;

const AddNewProduct = ({ onAdd }) => {
  const { register, setValue, control } = useForm();
  const { data: { getProducts: products } = {}, loading } =
    useQuery(GET_PRODUCTS);

  const handleSelect = (id) => {
    const product = products.find((p) => p._id === id);
    onAdd(product);
    setValue("product", "");
  };

  return (
    <Wrapper>
      <FormField>
        <Label>Odaberi proizvod</Label>
        <AutosuggestInput
          populateWith={products}
          loading={loading}
          control={control}
          onSelect={handleSelect}
          labelField="name"
          compareField="inventoryTag"
          valueField="_id"
          {...register("product")}
        />
      </FormField>
    </Wrapper>
  );
};

export default AddNewProduct;
