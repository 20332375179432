import React from "react";
import styled, { keyframes } from "styled-components";
import Space from "../../../components/structure/Space/Space";

const slideFromRight = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0)
    }
`;

const Wrapper = styled.div`
  width: 500px;
  animation: 0.2s ease-out ${slideFromRight};
  padding: 0.3rem 2rem 2rem 2rem;
  position: absolute;
  right: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: -10px 10px 20px 0px rgb(0 0 0 / 17%);
  border-bottom: 5px solid ${({ theme }) => theme.colors.red};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: 600;
    font-size: 0.8rem;
  }
`;

const UserData = ({ data }) => {
  return (
    <Wrapper loaded={data}>
      <h3>Informacije o korisniku</h3>
      <Space vertical="1rem" />
      <Row>
        <span>Puno ime</span>
        {data.fullName}
      </Row>
      <Row>
        <span>Username</span>
        {data.username}
      </Row>
      <Row>
        <span>Broj telefona</span>
        {data.contactInfo.phoneNumber}
      </Row>
      <Row>
        <span>Email</span>
        {data.contactInfo.email}
      </Row>
      <Space vertical="1rem" />
      <Row>
        <span>Odobreni kredit</span>
        {data.creditLimit?.approvedLimit
          ? `${data.creditLimit?.approvedLimit?.toFixed(2)} KM`
          : "Nije unešeno"}
      </Row>
      <Row>
        <span>Dug</span>
        {data.creditLimit?.debt
          ? `${data.creditLimit?.debt?.toFixed(2)} KM`
          : "Nije unešeno"}
      </Row>
      <Row>
        <span>Termin plaćanja</span>
        {data.creditLimit?.paymentTerm
          ? `${data.creditLimit?.paymentTerm} dana`
          : "Nije unešeno"}
      </Row>
    </Wrapper>
  );
};

export default UserData;
