import { useMutation, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import REMOVE_OFFER from "../../../apollo/mutations/removeOffer";
import SEND_TO_BUYER from "../../../apollo/mutations/sendToBuyer";
import GET_ORDERS from "../../../apollo/queries/getOrders";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import Spinner from "../../../components/elements/Spinner/Spinner";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import SendToInvoicing from "../../../components/forms/SendToInvoicing";
import { ModalContext } from "../../../context/modalContext";
import { orderNumber, price } from "../../../helpers/various";
import OfferPreview from "../Offers/OfferPreview";

const Wrapper = styled.div``;

const OrdersTable = ({ guestsOnly, sentToBuyerOnly }) => {
  const [data, setData] = useState([]);

  const { data: { getOrders } = {}, loading } = useQuery(GET_ORDERS);
  const { handleModal } = useContext(ModalContext);

  const [removeOfferMutation] = useMutation(REMOVE_OFFER, {
    onCompleted: () => toast.success("Narudžbenica uspješno obrisana"),
    onError: () => toast.error("Neuspješno brisanje narudžbenice"),
  });

  const [sendToBuyerMutation] = useMutation(SEND_TO_BUYER, {
    onCompleted: () => toast.success("Narudžbenica označena kao isporučena"),
    onError: () =>
      toast.error(
        "Neuspješno isporučena narudžbenica, kontaktirajte administratora"
      ),
  });

  const handlePreviewOffer = (offer) => {
    handleModal(<OfferPreview offer={offer} />);
  };

  const handleRemoveOrder = (offerId) => {
    if (window.confirm("Potvrdite brisanje narudžbenice")) {
      removeOfferMutation({
        variables: {
          offerId,
        },
        refetchQueries: ["getOrders"],
      });
    }
  };

  const handleSendToBuyer = (offerId) => {
    if (window.confirm("Označi narudžbenicu kao isporučenu?")) {
      sendToBuyerMutation({
        variables: {
          offerId,
        },
        refetchQueries: ["getOrders", "getNotifications"],
      });
    }
  };

  useEffect(() => {
    if (getOrders) {
      if (guestsOnly) {
        setData(
          getOrders.filter((offer) => offer.createdByGuest.status === true)
        );
      } else if (sentToBuyerOnly) {
        setData(getOrders.filter((offer) => offer.sentToBuyer.status === true));
      } else {
        setData(
          getOrders.filter(
            (offer) =>
              offer.createdByGuest.status !== true && !offer.sentToBuyer.status
          )
        );
      }
    }
  }, [guestsOnly, sentToBuyerOnly, getOrders]);

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Korisnik</TableHeader>
            <TableHeader>Broj ponude</TableHeader>
            <TableHeader>Ukupna cijena</TableHeader>
            <TableHeader>Ukupna cijena s PDV-om</TableHeader>
            <TableHeader>Datum kreiranja</TableHeader>
            <TableHeader>Poslano na fakturisanje</TableHeader>
            <TableHeader>Poslano u skladište</TableHeader>
            <TableHeader>Isporučeno</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((offer) => (
            <TableRow onClick={() => handlePreviewOffer(offer)} key={offer._id}>
              <TableCell>{offer?.user?.fullName || "Gost"}</TableCell>
              <TableCell>{orderNumber(offer.orderNumber)}</TableCell>
              <TableCell>{price(offer.priceToPay.withoutTax)}</TableCell>
              <TableCell>{price(offer.priceToPay.withTax)}</TableCell>
              <TableCell>
                {dayjs(offer.dateCreated).format("DD.MM.YYYY")}
              </TableCell>
              <TableCell>
                <strong>
                  {offer.sentToInvoicing?.status === true
                    ? dayjs(offer.sentToInvoicing?.date).format(
                        "DD.MM.YYYY @ hh:mm"
                      )
                    : null}
                </strong>
              </TableCell>
              <TableCell>
                <strong>
                  {offer.sentToWarehouse?.status === true
                    ? dayjs(offer.sentToWarehouse?.date).format(
                        "DD.MM.YYYY @ hh:mm"
                      )
                    : null}
                </strong>
              </TableCell>
              <TableCell>
                {offer.sentToBuyer?.status === true
                  ? dayjs(offer.sentToBuyer?.date).format("DD.MM.YYYY @ hh:mm")
                  : null}
              </TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={offer._id}
                  actions={[
                    {
                      showIf: !sentToBuyerOnly && !offer.sentToInvoicing.status,
                      label: "Šalji na fakturisanje",
                      action: () =>
                        handleModal(<SendToInvoicing offerId={offer._id} />),
                    },
                    {
                      showIf: !sentToBuyerOnly && !offer.sentToWarehouse.status,
                      label: "Šalji u magacin",
                      action: () =>
                        handleModal(
                          <SendToInvoicing
                            offerId={offer._id}
                            toWarehouse={true}
                          />
                        ),
                    },
                    {
                      showIf: !sentToBuyerOnly,
                      label: "Isporuči narudžbu",
                      action: () => handleSendToBuyer(offer._id),
                    },
                    {
                      showIf: !sentToBuyerOnly,
                      label: "Obriši narudžbenicu",
                      action: () => handleRemoveOrder(offer._id),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default OrdersTable;
