import gql from "graphql-tag";

const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      _id
      type
      subType
      affectedEntity
      affectedEntityId
      message
      seen
    }
  }
`;

export default GET_NOTIFICATIONS;
