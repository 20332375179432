import { useContext } from "react";
import { Context as CurrentUserContext } from "../../../context/currentUser";

const ProtectedContent = ({ onlyFor, children }) => {
  const {
    state: {
      authorized,
      currentUser: { role },
    },
  } = useContext(CurrentUserContext);

  const isAuth = (val) => {
    if (val === "logged-out" && !authorized) return true;
    if (val.includes(role) && authorized) return true;
  };

  return isAuth(onlyFor) ? children : null;
};

export default ProtectedContent;
