import gql from "graphql-tag";

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        id
        fullName
        role
      }
    }
  }
`;

export default LOGIN;
