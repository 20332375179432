import { useMutation } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import CREATE_OFFER from "../../../apollo/mutations/createOffer";
import { Context as OfferContext } from "../../../context/offerContext";
import { price } from "../../../helpers/various";
import Button from "../form/Button/Button";
import Spinner from "../Spinner/Spinner";
import Textarea from "../../elements/form/Textarea/Textarea";
import FormField from "../../elements/form/FormField/FormField";
import { useForm } from "react-hook-form";
import Label from "../form/Label/Label";

const Wrapper = styled.div``;

const ActionButtonsWrapper = styled.div`
  display: grid;
  grid-template: "buttons values";
  grid-template-columns: [first] auto [second] auto;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.almostAlbino};
  border-radius: 5px;
`;
const ActionButtons = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`;
const Values = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.almostAlbino};
  display: grid;
  height: 100%;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: ${({ theme }) => theme.colors.albino};
  align-items: center;
`;
const Cell = styled.div`
  padding: 1rem 2rem;
  :last-child {
    text-align: right;
    font-weight: bold;
  }
`;

const Totals = () => {
  const { register, getValues } = useForm();
  const history = useHistory();
  const {
    state: { offerCart, user },
    clearOffer,
  } = useContext(OfferContext);

  const [createOffer, { loading }] = useMutation(CREATE_OFFER, {
    onError: () => toast.error("Ponuda nije poslata/sačuvana"),
    fetchPolicy: "network-only",
  });

  const totalValue = offerCart.reduce((a, c) => c.totalPrice + a, 0);

  const handleClearOffer = () => {
    if (
      window.confirm(
        "Da li ste sigurni da želite da obrišete sve unose iz ponude"
      ) === true
    ) {
      clearOffer();
    }
  };

  useEffect(() => {
    return clearOffer();
  }, []);

  const handleSendOffer = (onlySave) => {
    const data = {
      onlySave: onlySave,
      orderData: {
        user,
        noteForBuyer: getValues("noteForBuyer"),
        cart: offerCart.map((offerItem) => {
          return {
            product: offerItem.data._id,
            dimension: offerItem.dimension,
            quantity: offerItem.quantity,
            price: parseFloat(offerItem.price),
          };
        }),
      },
    };
    if (window.confirm("Da li ste sigurni?")) {
      createOffer({
        variables: {
          ...data,
        },
        refetchQueries: ["getOffers", "getOrders"],
        awaitRefetchQueries: true,
      }).then(() => {
        toast.success("Ponuda poslata/sačuvana");
        clearOffer();
        if (onlySave) {
          history.push("/admin/orders");
        } else {
          history.push("/admin/offers");
        }
      });
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <FormField>
        <Label htmlFor="noteForBuyer">
          Napomena za kupca (biti će prikazana samo na emailu)
        </Label>
        <Textarea rows="5" {...register("noteForBuyer")}></Textarea>
      </FormField>
      <ActionButtonsWrapper>
        <ActionButtons>
          <Button
            width="100%"
            layout="round"
            color="secondary"
            onClick={handleClearOffer}
            disabled={offerCart?.length === 0}
          >
            Obriši sve
          </Button>
          <Button
            width="100%"
            layout="round"
            onClick={() => handleSendOffer(false)}
            disabled={offerCart?.length === 0}
          >
            Pošalji ponudu
          </Button>
          <Button
            width="100%"
            layout="round"
            color="hollow"
            onClick={() => handleSendOffer(true)}
            disabled={offerCart?.length === 0}
          >
            Snimi ponudu
          </Button>
        </ActionButtons>
        <Values>
          <Row>
            <Cell>Ukupno bez PDV-a</Cell>
            <Cell>{price(totalValue)}</Cell>
          </Row>
          <Row>
            <Cell>Ukupno sa PDV-om</Cell>
            <Cell>{price(totalValue + totalValue * 0.17)}</Cell>
          </Row>
        </Values>
      </ActionButtonsWrapper>
    </Wrapper>
  );
};

export default Totals;
