import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  writing-mode: vertical-lr;
  position: fixed;
  right: 10px;
  bottom: 10px;
  span {
    padding: 0.3rem;
    display: block;
  }
  strong {
    color: ${({ theme }) => theme.colors.red};
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      {/* <span>
        Sva prava pridržana {new Date().getFullYear()} © ImperialGlass d.o.o.
      </span> */}
      <span>
        Design & Development ©
        <a href="mailto:semperlabs@gmail.com">
          <strong>semperlabs</strong>
        </a>
      </span>
    </Wrapper>
  );
};

export default Footer;
