import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import CREATE_PRODUCT from "../../apollo/mutations/createProduct";
import GET_PRODUCT_CATEGORIES from "../../apollo/queries/getProductCategories";
import { FOLDERS } from "../../constants/constants";
import { ModalContext } from "../../context/modalContext";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import Select from "../elements/form/Select/Select";
import UploadFiles from "../elements/UploadFiles/UploadFiles";
import Distributor from "../structure/Distributor/Distributor";
import { createProductSchema } from "./schema";

const CreateProduct = ({ noTitle }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [files, setFiles] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const { handleModal } = useContext(ModalContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createProductSchema),
  });

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
    onCompleted: ({ createProduct: productId }) => {
      toast.success("Proizvod dodan");
      handleModal();
      history.push(`${pathname}/${productId}`);
    },
    onError: () => toast.error("Nije moguće dodati proizvod"),
  });

  const { loadingCategories, data: { getProductCategories: categories } = {} } =
    useQuery(GET_PRODUCT_CATEGORIES);

  const onSubmitHandler = (values) => {
    createProduct({
      variables: {
        productInput: { ...values, image: uploaded[0] },
      },
      refetchQueries: ["getProducts"],
    });
  };

  const handleUploadedFiles = (newFile) => {
    setUploaded([...uploaded, newFile]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      {!noTitle ? <FormHeader>Dodaj proizvod</FormHeader> : null}
      <FormField>
        <Label htmlFor="image">Slika proizvoda</Label>
        <UploadFiles
          onFileUpload={handleUploadedFiles}
          onSelect={setFiles}
          files={files}
          uploadFolder={FOLDERS.products}
        />
      </FormField>
      <FormField>
        <Label htmlFor="name" error={errors?.name?.message}>
          Naziv proizvoda
        </Label>
        <Input name="name" {...register("name")} />
      </FormField>
      <Distributor col="1-2">
        <FormField>
          <Label htmlFor="thickness" error={errors?.thickness?.message}>
            Debljina (mm)
          </Label>
          <Input
            name="thickness"
            {...register("thickness", { valueAsNumber: true })}
          />
        </FormField>
        <FormField>
          <Label htmlFor="inventoryTag" error={errors?.inventoryTag?.message}>
            Inventurni broj
          </Label>
          <Input name="inventoryTag" {...register("inventoryTag")} />
        </FormField>
      </Distributor>
      <Distributor col="2-1">
        <FormField>
          <Label htmlFor="category" error={errors?.category?.message}>
            Kategorija proizvoda
          </Label>
          <Select {...register("category")} loading={loadingCategories}>
            {categories?.map((category) => (
              <option value={category._id} key={category._id}>
                {category.name}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label htmlFor="sortingIndex" error={errors?.sortingIndex?.message}>
            Indeks za redoslijed
          </Label>
          <Input
            type="number"
            min="0"
            step="1"
            {...register("sortingIndex", { valueAsNumber: true })}
          />
        </FormField>
      </Distributor>
      <Distributor col="1-1">
        <FormField>
          <Label
            htmlFor="price.casePrice"
            error={errors?.price?.casePrice?.message}
          >
            Cijena palete
          </Label>
          <Input
            type="number"
            min="0.01"
            step="0.01"
            {...register("price.casePrice", { valueAsNumber: true })}
          />
        </FormField>
        <FormField>
          <Label
            htmlFor="price.regularPrice"
            error={errors?.price?.regularPrice?.message}
          >
            Cijena ploče
          </Label>
          <Input
            type="number"
            min="0.01"
            step="0.01"
            {...register("price.regularPrice", { valueAsNumber: true })}
          />
        </FormField>
      </Distributor>
      <Distributor stack="end" submitButtons>
        <Button layout="round" loading={loading}>
          Sačuvaj proizvod
        </Button>
      </Distributor>
    </form>
  );
};

export default CreateProduct;
