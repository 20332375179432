import gql from "graphql-tag";

const GET_USERS = gql`
  query getUsers {
    getUsers {
      _id
      username
      fullName
      role
      contactInfo {
        phoneNumber
        email
        address {
          location
          geo_lon
          geo_lat
        }
      }
      creditLimit {
        approvedLimit
        paymentTerm
        debt
      }
    }
  }
`;

export default GET_USERS;
