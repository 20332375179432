import React from "react";
import styled from "styled-components";
import DynamicRow from "./DynamicRow";
import OfferCart from "./OfferCart";
import Totals from "./Totals";
import Space from "../../structure/Space/Space";

const Wrapper = styled.div``;

const DynamicTable = () => {
  return (
    <Wrapper>
      <OfferCart />
      <DynamicRow />
      <Space vertical="3rem" />
      <Totals />
    </Wrapper>
  );
};

export default DynamicTable;
