import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *,*:after, *:before {
        box-sizing: border-box;
    };

    body, html {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
    };

    body {
        font-family: ${({ theme }) => theme.fonts.body};
        color:  ${({ theme }) => theme.colors.black};
        font-size: 16px;
    };

    a {
        color: inherit;
        text-decoration: none;
    }

    p {
        line-height: 1.5;
    };


    h1,h2,h3,h4,h5,h6 {
        font-family: ${({ theme }) => theme.fonts.heading};
    }

    input, select, button, textarea {
        font-size: 1rem;
        padding: 0.5em 0.7rem;
        font-family: inherit;
        border: none;
        outline: none;
        background-color: ${({ theme }) => theme.colors.lightestGrey};
        width: 100%;
        border-radius: 2px;
    }

    button {
        width: auto;
        padding: 0.8em 1.5rem;
    }

    .Toastify__toast {
    border: 1px solid #e3e3e3;
    font-family: inherit;
    }
`;
