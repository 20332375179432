import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  ${({ vertical, horizontal }) =>
    vertical ? `margin-top: ${vertical}` : `margin-left: ${horizontal}`};
`;

const Space = (props) => {
  return <Wrapper {...props} />;
};

export default Space;
