import { useQuery } from "@apollo/client";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { uniqBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import GET_USERS from "../../apollo/queries/getUsers";
import Spinner from "../../components/elements/Spinner/Spinner";
import UserData from "./Map/UserData";

const Wrapper = styled.div`
  margin: -40px;
  height: calc(100% + 80px);
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const Map = () => {
  const { data: { getUsers } = {} } = useQuery(GET_USERS);

  const { isLoaded } = useJsApiLoader({
    id: "google-maps",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  // root location
  const [myLat, myLng] = process.env.REACT_APP_MYLOCATION.split(",");
  const center = { lat: parseFloat(myLat), lng: parseFloat(myLng) };

  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (getUsers) {
      setMarkers(
        uniqBy(
          getUsers
            .map((user) => {
              // exclude users without locations
              if (
                user.contactInfo?.address?.geo_lon &&
                user.contactInfo?.address?.geo_lat
              ) {
                return {
                  id: user._id,
                  name: user.contactInfo.address.location,
                  position: {
                    lng: parseFloat(user.contactInfo.address.geo_lon),
                    lat: parseFloat(user.contactInfo.address.geo_lat),
                  },
                };
              }
              return false;
            })
            .filter(Boolean),
          "id"
        )
      );
    }
  }, [getUsers]);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const clustererOptions = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };

  const handleClick = (id, address) => {
    // get directions
    // load user data
    setUserData(getUsers?.find((user) => user._id === id));
  };
  return isLoaded ? (
    <Wrapper>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerClusterer options={clustererOptions}>
          {(clusterer) =>
            markers.map(({ id, position, name }) => {
              return (
                <Marker
                  key={id}
                  position={position}
                  clusterer={clusterer}
                  onClick={() => handleClick(id, name)}
                />
              );
            })
          }
        </MarkerClusterer>
      </GoogleMap>
      {userData ? <UserData data={userData} /> : null}
    </Wrapper>
  ) : (
    <Spinner />
  );
};

export default React.memo(Map);
