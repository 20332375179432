import React from "react";
import styled from "styled-components";
import { AiOutlineWarning } from "react-icons/ai";

const Wrapper = styled.div`
  margin-bottom: 0.3rem;
  font-size: 14px;
`;
const ErrorMessage = styled.div`
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red};
  svg {
    margin-right: 0.3rem;
  }
`;

const Label = ({ children, error, ...otherProps }) => {
  return (
    <Wrapper>
      <label {...otherProps}>{children}</label>
      {error ? (
        <ErrorMessage>
          <AiOutlineWarning size="1rem" />
          {error}
        </ErrorMessage>
      ) : null}
    </Wrapper>
  );
};

export default Label;
