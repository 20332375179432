import createDataContext from "./createDataContext";

const updateCart = (cart, newItem) => {
  // check if item exists
  const index = cart.findIndex((c) => c.id === newItem.id);
  if (index !== -1) {
    return cart.map((item) => {
      if (item.id === newItem.id) {
        // update the item
        return {
          ...item,
          quantity: item.quantity + newItem.quantity,
          surface: item.surface + newItem.surface,
          totalPrice: item.totalPrice + newItem.totalPrice,
        };
      }
      return item;
    });
  }
  return [...cart, newItem];
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case "add_to_cart":
      return {
        ...state,
        cart: updateCart(state.cart, action.payload),
        cartVisible: true,
      };
    case "show_cart":
      return { ...state, cartVisible: true };
    case "remove_from_cart":
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload),
      };
    case "hide_cart":
      return { ...state, cartVisible: false };
    case "clear_cart":
      return { ...state, cartVisible: false, cart: [] };
    default:
      return state;
  }
};

const addToCart = (dispatch) => (product) => {
  dispatch({ type: "add_to_cart", payload: product });
};

const removeFromCart = (dispatch) => (cartItemId) => {
  dispatch({ type: "remove_from_cart", payload: cartItemId });
};

const clearCart = (dispatch) => () => {
  dispatch({ type: "clear_cart" });
};

const hideCart = (dispatch) => () => {
  dispatch({ type: "hide_cart" });
};

const showCart = (dispatch) => () => {
  dispatch({ type: "show_cart" });
};

export const { Context, Provider } = createDataContext(
  cartReducer,
  { addToCart, removeFromCart, hideCart, showCart, clearCart },
  { cart: [], cartVisible: false }
);
