import gql from "graphql-tag";

const GET_USER_PRICES = gql`
  query getUserPrices($userId: ID!) {
    getUserPrices(userId: $userId) {
      _id
      user {
        _id
        username
        contactInfo {
          email
        }
      }
      product {
        inventoryTag
        attributes {
          dimensions {
            sheetWidth
            sheetHeight
            stockQuantity
          }
        }
        _id
        name
        category {
          name
        }
        price {
          regularPrice
          casePrice
        }
      }
      price {
        regularPrice
        casePrice
      }
    }
  }
`;

export default GET_USER_PRICES;
