import gql from "graphql-tag";

const SIGN_URL = gql`
  mutation signUrl($files: [FilesInput]) {
    signUrl(files: $files) {
      signedRequest
      url
      filename
      originalFilename
    }
  }
`;

export default SIGN_URL;
