import React, { useContext } from "react";
import styled from "styled-components";
import Button from "../../components/elements/form/Button/Button";
import OrderForm from "../../components/forms/Order.form";
import Distributor from "../../components/structure/Distributor/Distributor";
import Space from "../../components/structure/Space/Space";
import { Context as CartContext } from "../../context/cartContext";
import { ModalContext } from "../../context/modalContext";
import { price } from "../../helpers/various";

const Wrapper = styled.div``;
const TotalValues = styled.div`
  margin-bottom: 2rem;
  > div:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
const Value = styled.div`
  display: flex;
  justify-content: space-between;
  span:nth-child(2) {
    font-weight: 700;
  }
`;

const Checkout = styled.div``;

const Totals = () => {
  const {
    clearCart: clear,
    state: { cart: data },
  } = useContext(CartContext);

  const { handleModal } = useContext(ModalContext);

  if (!data.length) return null;

  const handleClear = () => {
    if (
      window.confirm(
        "Da li ste sigurni da želite da obrišete sve iz korpe?"
      ) === true
    ) {
      clear();
    }
  };

  const totalValue = data.reduce((a, c) => c.totalPrice + a, 0);

  return (
    <Wrapper>
      <TotalValues>
        <h3>Ukupno</h3>
        <Value>
          <span>Ukupno bez PDV-a</span>
          <span>{price(totalValue)}</span>
        </Value>
        <Value>
          <span>Ukupno sa PDV-om</span>
          <span>{price(totalValue + totalValue * 0.17)}</span>
        </Value>
      </TotalValues>
      <Space vertical="2rem" />
      <Checkout>
        <Distributor col="1-1">
          <Button
            width="100%"
            layout="round"
            color="secondary"
            onClick={handleClear}
          >
            Obriši sve
          </Button>
          <Button
            width="100%"
            layout="round"
            onClick={() => handleModal(<OrderForm data={data} />)}
          >
            Naruči
          </Button>
        </Distributor>
      </Checkout>
    </Wrapper>
  );
};

export default Totals;
