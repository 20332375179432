import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import logo from "../../../assets/static/logo.svg";

const Wrapper = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const Logo = () => {
  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  return (
    <Wrapper onClick={goHome}>
      <img src={logo} alt="Imperial Glass Logo" />
    </Wrapper>
  );
};

export default Logo;
