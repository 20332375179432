import React, { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { Context as CartContext } from "../../../context/cartContext";
import CartItems from "./CartItems";
import Totals from "./Totals";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 400px;
  background: ${({ theme }) => theme.colors.white};
  z-index: 999;
  padding: 2rem;
  box-shadow: 0 -10px 20px 20px rgb(36 36 36 / 17%);
  ${({ visible }) =>
    visible
      ? `
      opacity: 1;
    transform:translateX(0);
    `
      : `
      opacity: 0;
    transform:translateX(100%);
    `};

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.lightGrey};
  svg {
    :hover {
      color: ${({ theme }) => theme.colors.black};
      cursor: pointer;
    }
  }
`;

const Cart = () => {
  const {
    hideCart,
    clearCart,
    state: { cartVisible, cart },
  } = useContext(CartContext);

  // TODO: Detect outside click and close when out of focus

  return (
    <Wrapper visible={cartVisible}>
      <Heading>
        <h3>Korpa</h3>
        <Close onClick={hideCart}>
          <FaTimes size="20px" />
        </Close>
      </Heading>
      <CartItems data={cart} />
      <Totals data={cart} clear={clearCart} />
    </Wrapper>
  );
};

export default Cart;
