import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { omit } from "lodash";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CREATE_DIMENSION from "../../apollo/mutations/createDimension";
import GET_PRODUCT from "../../apollo/queries/getProduct";
import GET_STOCK from "../../apollo/queries/getStock";
import { ModalContext } from "../../context/modalContext";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import Distributor from "../structure/Distributor/Distributor";
import { productDimensionSchema } from "./schema";

const CreateDimension = ({ productId, populateFields }) => {
  const { handleModal } = useContext(ModalContext);
  const [createDimensionMutation, { loading }] = useMutation(CREATE_DIMENSION, {
    onCompleted: () => {
      toast.success("Dimenzija ažurirana/dodana");
      handleModal();
    },
    onError: (err) => {
      console.log(err);
      toast.error("Nemoguće snimiti dimenziju");
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productDimensionSchema),
  });

  const onSubmitHandler = (values) => {
    createDimensionMutation({
      variables: {
        productId,
        dimensionData: { ...values, _id: populateFields?._id },
      },
      refetchQueries: [
        {
          query: GET_PRODUCT,
          variables: {
            productId,
          },
        },
        {
          query: GET_STOCK,
        },
      ],
    });
  };

  useEffect(() => {
    if (populateFields) {
      reset(omit(populateFields, ["_id", "__typename"]));
    }
  }, [populateFields]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormHeader>Kreiraj/uredi dimenziju</FormHeader>
      <Distributor col="1-1">
        <FormField>
          <Label htmlFor="sheetWidth" error={errors?.sheetWidth?.message}>
            Širina (mm)
          </Label>
          <Input
            {...register("sheetWidth", { valueAsNumber: true })}
            type="number"
            min="0"
            step="1"
          />
        </FormField>
        <FormField>
          <Label htmlFor="sheetHeight" error={errors?.sheetHeight?.message}>
            Visina (mm)
          </Label>
          <Input
            {...register("sheetHeight", { valueAsNumber: true })}
            type="number"
            min="0"
            step="1"
          />
        </FormField>
      </Distributor>
      <FormField>
        <Label htmlFor="stockQuantity" error={errors?.stockQuantity?.message}>
          Trenutni lager (komada)
        </Label>
        <Input
          {...register("stockQuantity", { valueAsNumber: true })}
          type="number"
          min="0"
          step="1"
        />
      </FormField>
      <FormField>
        <Label
          htmlFor="lowStockLevelIndicator"
          error={errors?.lowStockLevelIndicator?.message}
        >
          Indikator niskog stanja lagera (npr. 100)
        </Label>
        <Input
          {...register("lowStockLevelIndicator", { valueAsNumber: true })}
          type="number"
          min="0"
          step="1"
        />
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button layout="round" loading={loading}>
          Sačuvaj
        </Button>
      </Distributor>
    </form>
  );
};

export default CreateDimension;
