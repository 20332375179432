import React, { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Context as CartContext } from "../../../context/cartContext";
import { price } from "../../../helpers/various";
import Distributor from "../../structure/Distributor/Distributor";
import Button from "../form/Button/Button";

const Wrapper = styled.div``;
const TotalValues = styled.div`
  margin-bottom: 2rem;
  > div:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
const Value = styled.div`
  display: flex;
  justify-content: space-between;
  span:nth-child(2) {
    font-weight: 700;
  }
`;

const Checkout = styled.div``;

const Totals = ({ data, clear }) => {
  const { hideCart } = useContext(CartContext);
  const history = useHistory();

  const handleCartRedirect = () => {
    hideCart();
    history.push("/order");
  };

  if (!data.length) return null;

  const handleClear = () => {
    if (
      window.confirm(
        "Da li ste sigurni da želite da obrišete sve iz korpe?"
      ) === true
    ) {
      clear();
    }
  };

  const totalValue = data.reduce((a, c) => c.totalPrice + a, 0);

  return (
    <Wrapper>
      <TotalValues>
        <h3>Ukupno</h3>
        <Value>
          <span>Ukupno bez PDV-a</span>
          <span>{price(totalValue)}</span>
        </Value>
        <Value>
          <span>Ukupno sa PDV-om</span>
          <span>{price(totalValue + totalValue * 0.17)}</span>
        </Value>
      </TotalValues>
      <Checkout>
        <Distributor col="1-1">
          <Button
            width="100%"
            layout="round"
            color="secondary"
            onClick={handleClear}
          >
            Obriši sve
          </Button>
          <Button width="100%" layout="round" onClick={handleCartRedirect}>
            Nastavi
          </Button>
        </Distributor>
      </Checkout>
    </Wrapper>
  );
};

export default Totals;
