import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CREATE_DRIVER from "../../apollo/mutations/createDriver";
import { ModalContext } from "../../context/modalContext";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import Distributor from "../structure/Distributor/Distributor";

const CreateDriver = () => {
  const { handleModal } = useContext(ModalContext);
  const { register, handleSubmit } = useForm();
  const [createDriverMutation] = useMutation(CREATE_DRIVER, {
    onCompleted: () => {
      handleModal();
      toast.success("Vozač kreiran");
    },
    onError: () => toast.error("Nemoguće kreirati vozača"),
  });

  const handleCreateDriver = (values) => {
    createDriverMutation({
      variables: {
        driver: { ...values },
      },
      refetchQueries: ["getDrivers"],
    });
  };

  return (
    <form onSubmit={handleSubmit(handleCreateDriver)}>
      <FormHeader>Dodaj vozača</FormHeader>
      <FormField>
        <Label htmlFor="firstName">Ime vozača</Label>
        <Input {...register("firstName", { required: true })} />
      </FormField>
      <FormField>
        <Label htmlFor="lastName">Prezime vozača</Label>
        <Input {...register("lastName")} />
      </FormField>
      <FormField>
        <Label htmlFor="phoneNumber">Broj telefona</Label>
        <Input {...register("phoneNumber")} />
      </FormField>
      <FormField>
        <Label htmlFor="address">Adresa vozača</Label>
        <Input {...register("address")} />
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button layout="round">Dodaj vozača</Button>
      </Distributor>
    </form>
  );
};

export default CreateDriver;
