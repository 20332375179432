import React from "react";
import styled from "styled-components";
import cutGlass from "../../../assets/static/cut-glass.png";
import sliderBg from "../../../assets/static/hero-slider.jpg";
import Container from "../../structure/Container/Container";

const Wrapper = styled.div`
  background-image: url(${sliderBg});
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  > div {
    display: flex;
  }
`;

const Left = styled.div`
  padding: 150px 0;
  flex: 1;
  @media screen and (max-width: 600px) {
    padding: 100px 0;
  }
`;
const Right = styled.div`
  flex: 1;
  padding-top: 50px;
  > img {
    position: absolute;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Slider = () => {
  return (
    <Wrapper>
      <Container size="lg">
        <Left>
          <h1>Svjetski kvalitet</h1>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </p>
        </Left>
        <Right>
          <img src={cutGlass} alt="Piece of glass" />
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Slider;
