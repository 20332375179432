import createDataContext from "./createDataContext";

const updateOfferCart = (cart, newItem) => {
  // check if item exists
  const index = cart.findIndex(
    (c) => c.id === newItem.id && c.price === newItem.price
  );
  if (index !== -1) {
    return cart.map((item) => {
      if (item.id === newItem.id && item.price === newItem.price) {
        // update the item
        return {
          ...item,
          quantity: item.quantity + newItem.quantity,
          surface: item.surface + newItem.surface,
          totalPrice: item.totalPrice + newItem.totalPrice,
        };
      }
      return item;
    });
  }
  return [...cart, newItem];
};

const offerReducer = (state, action) => {
  switch (action.type) {
    case "set_products":
      return { ...state, products: action.payload };
    case "set_product":
      return { ...state, selectedProduct: action.payload };
    case "add_to_offer":
      return {
        ...state,
        offerCart: updateOfferCart(state.offerCart, action.payload),
      };
    case "clear_offer":
      return { ...state, offerCart: [], selectedProduct: {} };
    case "remove_from_offer":
      return {
        ...state,
        offerCart: state.offerCart.filter((item) => item.id !== action.payload),
      };
    case "create_offer":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

const addToOffer = (dispatch) => (product) => {
  dispatch({ type: "add_to_offer", payload: product });
};

const removeFromOffer = (dispatch) => (offerItemId) => {
  dispatch({ type: "remove_from_offer", payload: offerItemId });
};

const clearOffer = (dispatch) => () => {
  dispatch({ type: "clear_offer" });
};

const setProduct = (dispatch) => (product) => {
  dispatch({ type: "set_product", payload: product });
};

const setProducts = (dispatch) => (products) => {
  dispatch({ type: "set_products", payload: products });
};

const createOffer = (dispatch) => (userId) => {
  dispatch({ type: "create_offer", payload: userId });
};

export const { Context, Provider } = createDataContext(
  offerReducer,
  {
    createOffer,
    addToOffer,
    removeFromOffer,
    clearOffer,
    setProduct,
    setProducts,
  },
  { offerCart: [], selectedProduct: {}, products: [], user: "" }
);
