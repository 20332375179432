import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import GET_PRODUCT from "../../apollo/queries/getProduct";
import Space from "../../components/structure/Space/Space";

import IconText from "../../components/elements/IconText/IconText";
import { FaCaretLeft } from "react-icons/fa";
import Spinner from "../../components/elements/Spinner/Spinner";
import DimensionsTable from "./ProductDetails/DimensionsTable";
import UpdateProductBasicInfo from "../../components/forms/UpdateProductBasicInfo";
import Tabs, { Tab } from "../../components/elements/Tabs/Tabs";
import Notice from "../../components/elements/Notice/Notice";

const Wrapper = styled.div``;

const ProductDetails = ({ match, history }) => {
  const { productId } = match.params;
  const { loading, data: { getProduct: data } = {} } = useQuery(GET_PRODUCT, {
    variables: {
      productId,
    },
  });

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <IconText
        onClick={history.goBack}
        text="Idi nazad"
        icon={FaCaretLeft}
        hoverable
      />
      <Space vertical="1rem" />
      <Notice
        type="warning"
        title="Dimenzije i lager"
        showIf={!data?.attributes?.dimensions?.length}
      >
        Nemojte zaboraviti dodati dimenzije proizvoda, u protivnom proizvod se
        neće moći naručiti. Navedeno možete uraditi na susjednom tabu
      </Notice>
      <Tabs container="md">
        <Tab title="Osnovni podaci">
          <UpdateProductBasicInfo data={data} />
        </Tab>
        <Tab title="Dimenzije i lager" data={data}>
          <DimensionsTable data={data} />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default ProductDetails;
