import React, { useContext } from "react";
import styled from "styled-components";
import Table, {
  TableHead,
  TableHeader,
  ActionHeader,
  TableRow,
  TableCell,
  ActionCell,
  TableBody,
} from "../../../components/elements/Table/Table";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import TopControls from "../../../components/structure/TopControls/TopControls";
import EditUserPrice from "../../../components/forms/UpdateUser/EditUserPrice";
import { ModalContext } from "../../../context/modalContext";
import Button from "../../../components/elements/form/Button/Button";
import { FaExclamation, FaFileInvoiceDollar } from "react-icons/fa";
import UpdatePrices from "../../../components/forms/UpdateUser/UpdatePrices";
import { useMutation } from "@apollo/client";
import RESET_PRICES from "../../../apollo/mutations/resetPrices";
import { toast } from "react-toastify";

const Wrapper = styled.div``;

const UserPricesTable = ({ loading, data: getUserPrices, userId }) => {
  const { handleModal } = useContext(ModalContext);
  const [resetPricesMutation, { loading: loadingReset }] = useMutation(
    RESET_PRICES,
    {
      onCompleted: () => toast.success("Fiksne cijene učitane"),
      onError: () => toast.error("Nije moguće učitati cijene"),
    }
  );

  const handleResetPrices = () => {
    if (prompt("Unesite POTVRDA za resetovanje svih cijena") === "POTVRDA") {
      resetPricesMutation({
        variables: { userId },
        refetchQueries: ["getUserPrices"],
      });
    }
  };

  return (
    <Wrapper>
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          onClick={handleResetPrices}
          icon={FaExclamation}
          loading={loadingReset}
        >
          Učitaj fiksne cijene s proizvoda
        </Button>
        <Button
          color="secondary"
          predefinedSize="small"
          layout="round"
          onClick={() => handleModal(<UpdatePrices userId={userId} />)}
          icon={FaFileInvoiceDollar}
        >
          Učitaj cijene s cjenovnika
        </Button>
      </TopControls>
      {getUserPrices?.length ? (
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableHeader>Naziv proizvoda</TableHeader>
              <TableHeader>Kategorija proizvoda</TableHeader>
              <TableHeader>Paleta</TableHeader>
              <TableHeader>Ploča</TableHeader>
              <ActionHeader></ActionHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {getUserPrices?.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.product.name}</TableCell>
                <TableCell>{item.product.category.name}</TableCell>
                <TableCell>{item.price.casePrice}</TableCell>
                <TableCell>{item.price.regularPrice}</TableCell>
                <ActionCell>
                  <ButtonDropdown
                    id={item.id}
                    actions={[
                      {
                        label: "Uredi cijenu",
                        action: () =>
                          handleModal(<EditUserPrice data={item} />),
                      },
                    ]}
                  />
                </ActionCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        "Nema podataka"
      )}
    </Wrapper>
  );
};

export default UserPricesTable;
