import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

export function useDebounce(value, delay, listener) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // check if listener value is empty
      if (!isEmpty(listener)) return;

      // Update debounced value after delay

      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // if listener value is changed remove handler
      if (!isEmpty(listener)) {
        clearTimeout(handler);
      }

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay, listener] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
