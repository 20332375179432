import React from "react";
import styled from "styled-components";
import ProtectedContent from "../../security/ProtectedContent/ProtectedContent";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import TopMenu from "../TopMenu/TopMenu";

const Wrapper = styled.div`
  padding: 0 50px 0 50px;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  :before {
    content: "";
    width: 50%;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.blue};
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

const Main = styled.div`
  flex: 1;
`;

const Background = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <TopMenu />
      <Background>
        <ProtectedContent onlyFor="logged-out">
          <Header />
        </ProtectedContent>
        <Main>{children}</Main>
        <Footer />
      </Background>
    </Wrapper>
  );
};

export default Layout;
