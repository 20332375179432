import gql from "graphql-tag";

const GET_TRUCKS = gql`
  query getTrucks {
    getTrucks {
      _id
      truck
      plates
    }
  }
`;

export default GET_TRUCKS;
