import React from "react";
import { AiOutlineInfoCircle, AiOutlineWarning } from "react-icons/ai";
import styled, { useTheme } from "styled-components";

const Icon = styled.div`
  padding-right: 2rem;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 2rem;
  border-radius: 5px;
  align-items: center;
  margin: 1rem 0;

  ${({ layout }) => `
        background-color: ${layout.background};
        border: 1px solid ${layout.accent};
        border-left-width: 5px;
        color: ${layout.accent};
        }
    `}
`;
const Content = styled.div``;
const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const Notice = ({ type, title, showIf, children }) => {
  const theme = useTheme();
  const types = {
    warning: {
      title: "Upozorenje",
      icon: AiOutlineWarning,
      accent: theme.colors.red,
      background: theme.colors.albinoRed,
    },
    info: {
      title: "Info",
      icon: AiOutlineInfoCircle,
      accent: theme.colors.grey,
      background: theme.colors.albino,
    },
  };

  return showIf || showIf === undefined ? (
    <Wrapper layout={types[type]}>
      <Icon>{React.createElement(types[type].icon, { size: 30 })}</Icon>
      <Content>
        <Title>{title || types[type].title}</Title>
        <Content>{children}</Content>
      </Content>
    </Wrapper>
  ) : null;
};

export default Notice;
