import React, { useContext } from "react";
import { FaHome, FaHotel, FaShoppingCart, FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import styled, { useTheme } from "styled-components";
import { Context as CartContext } from "../../../context/cartContext";
import { Context as CurrentUserContext } from "../../../context/currentUser";
import IconText from "../../elements/IconText/IconText";
import ProtectedContent from "../../security/ProtectedContent/ProtectedContent";
import Socials from "./Socials";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const Left = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
`;
const Right = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 3rem;
  }
`;

const TopMenu = () => {
  const theme = useTheme();
  const {
    showCart,
    state: { cart },
  } = useContext(CartContext);

  const { logout } = useContext(CurrentUserContext);

  return (
    <Wrapper>
      <Left>
        <Socials />
      </Left>
      <Right>
        <IconText
          iconColor={theme.colors.red}
          iconSize="1.5rem"
          icon={FaHome}
          text="Naslovna"
          link="/"
        />
        <ProtectedContent onlyFor="logged-out">
          <IconText
            iconColor={theme.colors.red}
            iconSize="1.5rem"
            icon={FaUserCircle}
            text="Loguj se"
            link="/login"
          />
        </ProtectedContent>
        <ProtectedContent onlyFor={["admin"]}>
          <IconText
            iconColor={theme.colors.red}
            iconSize="1.5rem"
            icon={FaHotel}
            text="Administracija"
            link="/admin/users"
          />
        </ProtectedContent>
        <ProtectedContent onlyFor="logged-out">
          <IconText
            iconColor={theme.colors.red}
            iconSize="1.5rem"
            icon={FaShoppingCart}
            text="Korpa"
            hoverable
            counter={cart.length}
            onClick={showCart}
          />
        </ProtectedContent>
        <ProtectedContent onlyFor={["admin"]}>
          <IconText
            iconColor={theme.colors.red}
            iconSize="1.5rem"
            icon={MdLogout}
            text="Log out"
            onClick={logout}
            hoverable
          />
        </ProtectedContent>
      </Right>
    </Wrapper>
  );
};

export default TopMenu;
