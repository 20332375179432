export const roles = [
  {
    value: "admin",
    label: "Administrator",
  },
  {
    value: "customer",
    label: "Mušterija",
  },
];

export const FOLDERS = {
  images: "images",
  products: "product",
  productCategories: "productcategory",
};

export const PAYMENT_METHODS = {
  BANK_ACCOUNT: "Žiralno",
  CASH: "Keš",
};

export const NOTIFICATION_TYPES = {
  INFO: "Info",
  WARNING: "Upozorenje",
  CRITICAL: "Kritično",
};

export const NOTIFICATION_SUBTYPES = {
  LOW_STOCK: "Niska zaliha",
};

export const NOTIFICATION_ENTITIES = {
  Product: "Proizvod",
};
