import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  align-items: start;
  grid-gap: ${({ gap }) => (gap ? gap : "1rem")};
  ${({ cols }) => cols && `grid-template-columns: ${cols}`};
  ${({ rows }) => rows && `grid-template-rows: ${rows}`};
  ${({ stack }) =>
    stack &&
    `
    display: flex;
    justify-content: flex-${stack};
    `};
  ${({ align }) => align && `align-items: flex-${align}`};
  ${({ submitButtons }) => submitButtons && `margin-top: 60px`};

  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

const Distributor = ({ col, row, children, ...otherProps }) => {
  const cols =
    col &&
    col
      .split("-")
      .map((c) => `${c}fr`)
      .join(" ");

  const rows =
    row &&
    row
      .split("-")
      .map((r) => `${r}fr`)
      .join(" ");
  return (
    <Wrapper cols={cols} rows={rows} {...otherProps}>
      {children}
    </Wrapper>
  );
};

export default Distributor;
