import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`;
const Image = styled.div`
  width: 100%;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  :after {
    content: "";
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgb(0 0 0 / 33%);
    z-index: 10000000;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  :hover:after {
    opacity: 1;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SelectedFiles = ({ files, uploaded, onRemove }) => {
  const renderFiles = uploaded?.length ? uploaded : files;
  console.log(renderFiles?.length);
  return (
    <Wrapper>
      {renderFiles.map((file, i) => {
        const src = uploaded?.length
          ? process.env.REACT_APP_AWS_BUCKET + file
          : URL.createObjectURL(file);
        return (
          <Image key={i} onClick={() => onRemove(i)}>
            <img src={src} alt="file" />
          </Image>
        );
      })}
    </Wrapper>
  );
};

export default SelectedFiles;
