import gql from "graphql-tag";

const UPDATE_PRODUCT_BASIC_INFO = gql`
  mutation updateProductBasicInfo(
    $productId: ID!
    $category: ID!
    $name: String
    $inventoryTag: String
    $regularPrice: Float
    $casePrice: Float
    $thickness: Float
    $sortingIndex: Int
  ) {
    updateProductBasicInfo(
      productId: $productId
      category: $category
      name: $name
      inventoryTag: $inventoryTag
      regularPrice: $regularPrice
      casePrice: $casePrice
      thickness: $thickness
      sortingIndex: $sortingIndex
    )
  }
`;

export default UPDATE_PRODUCT_BASIC_INFO;
