import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import UPDATE_PRODUCT_BASIC_INFO from "../../apollo/mutations/updateProductBasicInfo";
import GET_PRODUCT_CATEGORIES from "../../apollo/queries/getProductCategories";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import Select from "../elements/form/Select/Select";
import Distributor from "../structure/Distributor/Distributor";
import Space from "../structure/Space/Space";

const UpdateProductBasicInfo = ({ data }) => {
  const { register, setValue, handleSubmit, getValues } = useForm();
  const [updateProduct, { loading }] = useMutation(UPDATE_PRODUCT_BASIC_INFO, {
    onCompleted: () => toast.success("Proizvod ažuriran"),
    onError: () => toast.error("Proizvod nije ažuriran"),
  });

  const { data: { getProductCategories } = {}, loading: loadingCategories } =
    useQuery(GET_PRODUCT_CATEGORIES);

  useEffect(() => {
    if (data && setValue) {
      setValue("name", data.name);
      setValue("category", data.category._id);
      setValue("inventoryTag", data.inventoryTag);
      setValue("regularPrice", data.price.regularPrice);
      setValue("casePrice", data.price.casePrice);
      setValue("thickness", data.attributes?.thickness);
      setValue("sortingIndex", data.sortingIndex);
    }
  }, [data]);

  console.log(getValues());

  const onSubmit = (values, e) => {
    e.preventDefault();
    updateProduct({
      variables: {
        productId: data._id,
        ...values,
      },
      refetchQueries: ["getProducts"],
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader>Osnovne informacije</FormHeader>
      <FormField>
        <Label htmlFor="name">Naziv proizvoda</Label>
        <Input {...register("name")} />
      </FormField>
      <Distributor col="1-1-1">
        <FormField>
          <Label htmlFor="name">Kategorija proizvoda</Label>
          <Select {...register("category")} loading={loadingCategories}>
            {getProductCategories?.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label htmlFor="inventoryTag">Inventurni broj</Label>
          <Input {...register("inventoryTag")} />
        </FormField>
        <FormField>
          <Label htmlFor="sortingIndex">Indeks za sortiranje</Label>
          <Input {...register("sortingIndex", { valueAsNumber: true })} />
        </FormField>
      </Distributor>
      <Space vertical="4rem" />
      <FormHeader>Cijene proizvoda</FormHeader>
      <Distributor col="1-1">
        <FormField>
          <Label htmlFor="regularPrice">Cijena ploče (KM)</Label>
          <Input {...register("regularPrice", { valueAsNumber: true })} />
        </FormField>
        <FormField>
          <Label htmlFor="casePrice">Cijena palete (KM)</Label>
          <Input {...register("casePrice", { valueAsNumber: true })} />
        </FormField>
      </Distributor>
      <Space vertical="4rem" />
      <FormHeader>Atributi proizvoda</FormHeader>
      <FormField>
        <Label htmlFor="thickness">Debljina (mm)</Label>
        <Input {...register("thickness", { valueAsNumber: true })} />
      </FormField>
      <FormField>
        <Button layout="round" color="secondary" loading={loading}>
          Spremi izmjene
        </Button>
      </FormField>
    </form>
  );
};

export default UpdateProductBasicInfo;
