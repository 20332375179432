import React from "react";
import styled from "styled-components";
import Search from "../components/elements/Search/Search";
import Slider from "../components/elements/Slider/Slider";

const Wrapper = styled.div``;

const Home = () => {
  return (
    <Wrapper>
      <Slider />
      <Search />
    </Wrapper>
  );
};

export default Home;
