import gql from "graphql-tag";

const GET_ORDERS = gql`
  query getOrders {
    getOrders {
      _id
      user {
        _id
        username
        fullName
        company
      }
      orderNumber
      cart {
        product {
          name
        }
        totalPrice
        surface
        quantity
        price
        dimension {
          sheetWidth
          sheetHeight
        }
      }
      priceToPay {
        withoutTax
        withTax
      }
      dateCreated
      sentToBuyer {
        date
        status
      }
      approvedByAdministrator {
        date
        status
      }
      createdByGuest {
        status
        contactInfo {
          fullName
          phoneNumber
        }
      }
      note
      sentToWarehouse {
        status
        date
      }
      sentToInvoicing {
        status
        date
      }
    }
  }
`;

export default GET_ORDERS;
