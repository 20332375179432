import gql from "graphql-tag";

const GET_PRICE_LISTS = gql`
  query getPriceLists {
    getPriceLists {
      _id
      name
      prices {
        product {
          _id
          name
          category {
            name
          }
        }
        regularPrice
        casePrice
      }
      dateCreated
    }
  }
`;

export default GET_PRICE_LISTS;
