import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { roles } from "../../../constants/constants";
import Select from "../../elements/form/Select/Select";
import Button from "../../elements/form/Button/Button";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { ModalContext } from "../../../context/modalContext";
import UPDATE_USER from "../../../apollo/mutations/updateUser";
import { omit } from "lodash";
import Space from "../../structure/Space/Space";
import { updateUserSchema } from "../schema";
import FormHeader from "../../elements/form/FormHeader/FormHeader";
import Distributor from "../../structure/Distributor/Distributor";
import Label from "../../elements/form/Label/Label";
import FormField from "../../elements/form/FormField/FormField";
import Input from "../../elements/form/Input/Input";
import ChooseLocation from "../ChooseLocation.form";

const BasicUserInfoSection = ({ populateFields }) => {
  const { handleModal, setData, data } = useContext(ModalContext);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateUserSchema),
  });

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => toast.success("Korisnički podaci ažurirani"),
    onError: (e) => {
      console.log(e);
      toast.error("Nije moguće spasiti podatke");
    },
  });

  useEffect(() => {
    if (populateFields) {
      const fields = getValues();
      if (fields) {
        reset(
          omit(populateFields, [
            "_id",
            "__typename",
            "contactInfo.__typename",
            "contactInfo.address.__typename",
            "creditLimit.__typename",
          ])
        );
        setData({
          address: { ...populateFields?.contactInfo?.address },
        });
      }
    }
  }, [populateFields]);

  console.log("POPULATE", populateFields);

  // update location on change from modal
  useEffect(() => {
    if (data?.address) {
      const { location, geo_lon, geo_lat } = data.address;
      setValue("contactInfo.address.location", location);
      setValue("contactInfo.address.geo_lon", geo_lon);
      setValue("contactInfo.address.geo_lat", geo_lat);
    }
  }, [data.address]);

  const handleChooseLocation = (e) => {
    e.preventDefault();
    handleModal(<ChooseLocation />);
  };

  const onSubmitHandler = (values) => {
    if (window.confirm("Ažuriraj podatke korisnika?")) {
      updateUser({
        variables: {
          userId: populateFields?._id,
          userData: { ...values },
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormHeader>Osnovne informacije</FormHeader>
      <Distributor col="2-1">
        <FormField>
          <Label htmlFor="username" error={errors.username?.message}>
            Korisničko ime
          </Label>
          <Input {...register("username")} autoComplete="new-password" />
        </FormField>
        <FormField>
          <Label htmlFor="role" error={errors.role?.message}>
            Uloga
          </Label>
          <Select {...register("role")}>
            {roles.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
          </Select>
        </FormField>
      </Distributor>
      <Distributor col="1-1">
        <FormField>
          <Label htmlFor="company" error={errors.company?.message}>
            Naziv firme
          </Label>
          <Input {...register("company")} />
        </FormField>
        <FormField>
          <Label htmlFor="fullName" error={errors.fullName?.message}>
            Ime i prezime
          </Label>
          <Input {...register("fullName")} />
        </FormField>
      </Distributor>
      <Space vertical="4rem" />
      <FormHeader>Kontakt informacije</FormHeader>
      <Distributor col="1-1" align="end">
        <FormField>
          <Label
            htmlFor="contactInfo.email"
            error={errors?.contactInfo?.email?.message}
          >
            Email
          </Label>
          <Input {...register("contactInfo.email")} />
        </FormField>
        <FormField>
          <Label
            htmlFor="contactInfo.phoneNumber"
            error={errors?.contactInfo?.phoneNumber?.message}
          >
            Broj telefona
          </Label>
          <Input {...register("contactInfo.phoneNumber")} />
        </FormField>
      </Distributor>
      <Distributor col="5-2" align="end">
        <FormField>
          <Label
            htmlFor="contactInfo.address.location"
            error={errors.contactInfo?.address?.location?.message}
          >
            Lokacija (adresa)
          </Label>
          <Input {...register("contactInfo.address.location")} />
        </FormField>
        <FormField>
          <Button
            color="secondary"
            layout="round"
            width="100%"
            onClick={handleChooseLocation}
          >
            Odaberi lokaciju
          </Button>
        </FormField>
      </Distributor>

      <Distributor col="1-1">
        <FormField>
          <Label
            htmlFor="contactInfo.address.geo_lon"
            error={errors.contactInfo?.address?.geo_lon?.message}
          >
            Geografska dužina
          </Label>
          <Input {...register("contactInfo.address.geo_lon")} readOnly />
        </FormField>

        <FormField>
          <Label
            htmlFor="contactInfo.address.geo_lat"
            error={errors.contactInfo?.address?.geo_lat?.message}
          >
            Geografska širina
          </Label>
          <Input {...register("contactInfo.address.geo_lat")} readOnly />
        </FormField>
      </Distributor>

      <FormHeader>Limit i kreditiranje</FormHeader>
      <Distributor col="1-1-1">
        <FormField>
          <Label htmlFor="creditLimit.approvedLimit">Odobreni limit (KM)</Label>
          <Input
            {...register("creditLimit.approvedLimit", { valueAsNumber: true })}
          />
        </FormField>
        <FormField>
          <Label htmlFor="creditLimit.paymentTerm">
            Termin plaćanja (dana)
          </Label>
          <Input
            {...register("creditLimit.paymentTerm", { valueAsNumber: true })}
          />
        </FormField>
        <FormField>
          <Label htmlFor="creditLimit.debt">Trenutni dug (KM)</Label>
          <Input {...register("creditLimit.debt", { valueAsNumber: true })} />
        </FormField>
      </Distributor>

      <FormField>
        <Button layout="round" loading="">
          Spasi
        </Button>
      </FormField>
    </form>
  );
};

export default BasicUserInfoSection;
