import React from "react";
import styled, { useTheme } from "styled-components";
import Spinner from "../../Spinner/Spinner";

const Wrapper = styled.div`
  position: relative;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};

    :hover:not([disabled]) {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
    }

    ${({ layout }) => layout};
    ${({ color }) => color};
    ${({ predefinedSize }) => predefinedSize};

    width: ${({ width }) => (width ? width : "auto")};

    svg {
      opacity: 0.7;
    }

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
    :disabled {
      background: ${({ theme }) => theme.colors.almostAlbino};
      color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
`;

const Button = React.forwardRef(
  (
    {
      icon,
      iconSize,
      color,
      layout,
      predefinedSize,
      width,
      children,
      loading,
      ...otherProps
    },
    ref
  ) => {
    const theme = useTheme();

    // UNCLEAR: Loads as inline style string, instead of compiled stylesheet

    const colors = {
      primary: `
        color: ${theme.colors.white};
        background-color: ${theme.colors.blue};
      `,
      secondary: `
        color: ${theme.colors.white};
        background-color: ${theme.colors.red};
      `,
      hollow: `
          background-color: transparent;
          color: ${theme.colors.grey};
          border: 2px solid ${theme.colors.lighterGrey};
          :hover {
              background-color: ${theme.colors.red};
              color: ${theme.colors.white};
              border-color: transparent;
          }
      `,
    };

    const layouts = {
      round: `
      border-radius: 5px;
    `,
    };

    const sizes = {
      small: `
        font-size: 0.9rem;
        padding: 0.6rem 0.8rem;
      `,
      extraSmall: `
        font-size: 0.9rem;
        padding: 0.2rem;
      `,
    };

    return (
      <Wrapper
        width={width}
        layout={layout && layouts[layout]}
        color={color && colors[color]}
        predefinedSize={predefinedSize && sizes[predefinedSize]}
      >
        <button ref={ref} {...otherProps}>
          {loading ? (
            <Spinner height="0.5em" width="0.2em" />
          ) : icon ? (
            React.createElement(icon, { size: iconSize || 20 })
          ) : null}
          {children ? <span>{children}</span> : null}
        </button>
      </Wrapper>
    );
  }
);

export default Button;
