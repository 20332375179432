import React, { useContext, useEffect } from "react";
import FormField from "../elements/form/FormField/FormField";
import Label from "../elements/form/Label/Label";
import { useForm } from "react-hook-form";
import Input from "../elements/form/Input/Input";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import { roles } from "../../constants/constants";
import Select from "../elements/form/Select/Select";
import Button from "../elements/form/Button/Button";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import CREATE_USER from "../../apollo/mutations/createUser";
import GET_PRICE_LISTS from "../../apollo/queries/getPriceLists";
import { useHistory } from "react-router";
import { ModalContext } from "../../context/modalContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { createUserSchema } from "./schema";
import Distributor from "../structure/Distributor/Distributor";

const CreateUser = ({ populateFields }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createUserSchema),
  });

  const { handleModal } = useContext(ModalContext);

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser: userId }) => {
      toast.success("Korisnik kreiran");
      handleModal();
      history.push(`/admin/users/${userId}`);
    },
    onError: () => toast.error("Greška pri snimanju korisnika"),
  });

  const { data: { getPriceLists } = {}, loading: loadingPriceLists } =
    useQuery(GET_PRICE_LISTS);

  useEffect(() => {
    if (populateFields) {
      const fields = getValues();
      if (fields) {
        Object.keys(fields).map((key) => setValue(key, populateFields[key]));
        setValue("email", populateFields?.contactInfo?.email);
      }
    }
    setValue("role", "customer");
  }, [populateFields]);

  const onSubmitHandler = (values) => {
    if (window.confirm("Spasite korisnika?")) {
      createUser({
        variables: { userData: { ...values } },
        refetchQueries: ["getUsers"],
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormHeader>Dodaj korisnika</FormHeader>
      <FormField>
        <Label htmlFor="username" error={errors.username?.message}>
          Korisničko ime
        </Label>
        <Input {...register("username")} autoComplete="new-password" />
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label htmlFor="password" error={errors.password?.message}>
            Password
          </Label>
          <Input
            type="password"
            {...register("password")}
            autoComplete="new-password"
          />
        </FormField>

        <FormField>
          <Label htmlFor="email" error={errors.email?.message}>
            Email
          </Label>
          <Input {...register("email")} />
        </FormField>
      </Distributor>
      <FormField>
        <Label htmlFor="fullName" error={errors.fullName?.message}>
          Ime i prezime
        </Label>
        <Input {...register("fullName")} />
      </FormField>
      <FormField>
        <Label htmlFor="company" error={errors.company?.message}>
          Naziv firme
        </Label>
        <Input {...register("company")} />
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label htmlFor="role" error={errors.role?.message}>
            Uloga
          </Label>
          <Select {...register("role")}>
            {roles.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label htmlFor="priceList" error={errors.priceList?.message}>
            Učitaj cijene s cjenovnika
          </Label>
          <Select {...register("priceList")} loading={loadingPriceLists}>
            {getPriceLists?.map((pl) => (
              <option key={pl._id} value={pl._id}>
                {pl.name} ({pl.prices.length})
              </option>
            ))}
          </Select>
        </FormField>
      </Distributor>
      <Distributor stack="end" submitButtons>
        <Button type="submit" layout="round" loading={loading}>
          Spasi
        </Button>
      </Distributor>
    </form>
  );
};

export default CreateUser;
