import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context as OfferContext } from "../../../context/offerContext";
import { useDebounce } from "../../../hooks/useDebounce";
import Input from "../form/Input/Input";

const Wrapper = styled.div`
  position: relative;
`;
const ResultsWrapper = styled.div`
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  width: 100%;
  padding: 10px 0;
  box-shadow: 0 5px 4px 0px rgb(0 0 0 / 10%);
`;
const ResultItemWrapper = styled.div`
  padding: 0.7rem 1rem;
  display: block;
  :hover {
    background-color: ${({ theme }) => theme.colors.albino};
    cursor: pointer;
  }
`;

const ResultItem = ({ data, ...otherProps }) => {
  return <ResultItemWrapper {...otherProps}>{data.name}</ResultItemWrapper>;
};

const DynamicInput = () => {
  const [productInput, setProductInput] = useState("");
  const {
    state: { products, selectedProduct },
    setProduct,
  } = useContext(OfferContext);
  const debouncedSearchTerm = useDebounce(productInput, 200, selectedProduct);
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    const { value } = e.target;
    setProductInput(value);
  };

  const handleFocus = () => {
    setProduct({});
    setProductInput("");
  };

  useEffect(() => {
    if (isEmpty(selectedProduct)) {
      setProductInput("");
    }
  }, [selectedProduct]);

  const handleSelectProduct = ({ product, price }) => {
    setProduct({ ...product, price });
    setProductInput(product.name);
    setSearchResults([]);
  };

  const handleSearch = (productName) => {
    setSearchResults(
      products
        ?.filter(({ product }) => {
          return (
            product?.inventoryTag
              ?.toLowerCase()
              ?.includes(productName.toLowerCase()) ||
            product?.name?.toLowerCase()?.includes(productName.toLowerCase())
          );
        })
        .splice(0, 5)
    );
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <Wrapper>
      <Input
        onChange={handleChange}
        value={productInput}
        onFocus={handleFocus}
      />
      {searchResults?.length ? (
        <ResultsWrapper>
          {searchResults.map((price) => (
            <ResultItem
              key={price.product._id}
              data={price.product}
              onClick={() => handleSelectProduct(price)}
            />
          ))}
        </ResultsWrapper>
      ) : null}
    </Wrapper>
  );
};

export default DynamicInput;
