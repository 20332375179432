import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import FORWARD_PRICE_LIST_TO_EMAIL from "../../../apollo/mutations/forwardPriceListToEmail";
import REMOVE_PRICE_LIST from "../../../apollo/mutations/removePriceList";
import GET_PRICE_LISTS from "../../../apollo/queries/getPriceLists";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import Spinner from "../../../components/elements/Spinner/Spinner";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";

const Wrapper = styled.div``;

const PriceListsTable = () => {
  const { data: { getPriceLists } = {}, loading } = useQuery(GET_PRICE_LISTS);

  const [forwardPricelistMutation] = useMutation(FORWARD_PRICE_LIST_TO_EMAIL, {
    onCompleted: () => toast.success("Cjenovnik proslijeđen"),
    onError: () => toast.error("Nemoguće proslijediti cjenovnik"),
  });

  const [removePriceListMutation] = useMutation(REMOVE_PRICE_LIST, {
    onCompleted: () => toast.success("Cjenovnik obrisan"),
    onError: () => toast.error("Nemoguće obrisati cjenovnik"),
  });

  const history = useHistory();
  const { pathname } = useLocation();

  const handleRemovePriceList = (priceListId) => {
    if (window.confirm("Potvrdite brisanje cjenovnika")) {
      removePriceListMutation({
        variables: {
          priceListId,
        },
        refetchQueries: ["getPriceLists"],
      });
    }
  };

  const handleForwardPriceList = (priceListId) => {
    const email = prompt("Na koji email želite da proslijedite cjenovnik?");
    if (!email) return;

    forwardPricelistMutation({
      variables: {
        priceListId,
        email,
      },
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Naziv cjenovnika</TableHeader>
            <TableHeader>Datum kreiranja</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getPriceLists?.map((priceList) => (
            <TableRow
              key={priceList._id}
              onClick={() =>
                history.push({
                  pathname: `${pathname}/edit/${priceList._id}`,
                })
              }
            >
              <TableCell>{priceList.name}</TableCell>
              <TableCell>{priceList.dateCreated}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={priceList._id}
                  actions={[
                    {
                      label: "Proslijedi cjenovnik",
                      action: () => handleForwardPriceList(priceList._id),
                    },
                    {
                      label: "Obriši cjenovnik",
                      action: () => handleRemovePriceList(priceList._id),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default PriceListsTable;
