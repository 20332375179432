import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  ${({ size }) => (size ? `padding: 0 calc((100% - ${size}) / 2)` : "100%")};
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

const Container = ({ size, children }) => {
  const sizes = {
    sm: "800px",
    md: "1000px",
    lg: "1200px",
    xl: "1600px",
  };
  return <Wrapper size={sizes[size]}>{children}</Wrapper>;
};

export default Container;
