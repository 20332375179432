import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CREATE_TRUCK from "../../apollo/mutations/createTruck";
import { ModalContext } from "../../context/modalContext";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import Distributor from "../structure/Distributor/Distributor";

const CreateTruck = () => {
  const { handleModal } = useContext(ModalContext);
  const { register, handleSubmit } = useForm();
  const [createTruckMutation] = useMutation(CREATE_TRUCK, {
    onCompleted: () => {
      handleModal();
      toast.success("Kamion kreiran");
    },
    onError: () => toast.error("Nemoguće kreirati kamion"),
  });

  const handleCreateTruck = (values) => {
    createTruckMutation({
      variables: {
        truck: { ...values },
      },
      refetchQueries: ["getTrucks"],
    });
  };

  return (
    <form onSubmit={handleSubmit(handleCreateTruck)}>
      <FormHeader>Dodaj kamion</FormHeader>
      <FormField>
        <Label htmlFor="truck">Naziv kamiona</Label>
        <Input {...register("truck", { required: true })} />
      </FormField>
      <FormField>
        <Label htmlFor="plates">Tablice</Label>
        <Input {...register("plates", { required: true })} />
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button layout="round">Dodaj kamion</Button>
      </Distributor>
    </form>
  );
};

export default CreateTruck;
