import { useMutation, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import REMOVE_OFFER from "../../../apollo/mutations/removeOffer";
import GET_OFFERS from "../../../apollo/queries/getOffers";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import Spinner from "../../../components/elements/Spinner/Spinner";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import SendToInvoicing from "../../../components/forms/SendToInvoicing";
import SendToInvoicingAndWarehouse from "../../../components/forms/SendToInvoicingAndWarehouse";
import { ModalContext } from "../../../context/modalContext";
import { orderNumber, price } from "../../../helpers/various";
import OfferPreview from "./OfferPreview";

const Wrapper = styled.div``;

const OffersTable = ({ guestsOnly }) => {
  const [data, setData] = useState([]);

  const { data: { getOffers } = {}, loading } = useQuery(GET_OFFERS);
  const { handleModal } = useContext(ModalContext);

  const [removeOfferMutation] = useMutation(REMOVE_OFFER, {
    onCompleted: () => toast.success("Ponuda uspješno obrisana"),
    onError: () => toast.error("Neuspješno brisanje ponude"),
  });

  const handlePreviewOffer = (offer) => {
    handleModal(<OfferPreview offer={offer} />);
  };

  const handleRemoveOffer = (offerId) => {
    if (window.confirm("Potvrdite brisanje ponude")) {
      removeOfferMutation({
        variables: {
          offerId,
        },
        refetchQueries: ["getOffers"],
      });
    }
  };

  useEffect(() => {
    if (getOffers) {
      if (guestsOnly) {
        setData(
          getOffers.filter((offer) => offer.createdByGuest.status === true)
        );
      } else {
        setData(
          getOffers.filter((offer) => offer.createdByGuest.status !== true)
        );
      }
    }
  }, [guestsOnly, getOffers]);

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Korisnik</TableHeader>
            <TableHeader>Broj ponude</TableHeader>
            <TableHeader>Ukupna cijena</TableHeader>
            <TableHeader>Ukupna cijena s PDV-om</TableHeader>
            <TableHeader>Datum kreiranja</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((offer) => (
            <TableRow onClick={() => handlePreviewOffer(offer)} key={offer._id}>
              <TableCell>{offer?.user?.fullName || "Gost"}</TableCell>
              <TableCell>{orderNumber(offer.orderNumber)}</TableCell>
              <TableCell>{price(offer.priceToPay.withoutTax)}</TableCell>
              <TableCell>{price(offer.priceToPay.withTax)}</TableCell>
              <TableCell>
                {dayjs(offer.dateCreated).format("DD.MM.YYYY")}
              </TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={offer._id}
                  actions={[
                    {
                      showIf:
                        !offer.sentToInvoicing.status &&
                        !offer.sentToWarehouse.status,
                      label: "Šalji na fakturisanje i magacin",
                      action: () =>
                        handleModal(
                          <SendToInvoicingAndWarehouse offerId={offer._id} />
                        ),
                    },
                    {
                      showIf: !offer.sentToInvoicing.status,
                      label: "Šalji samo na fakturisanje",
                      action: () =>
                        handleModal(<SendToInvoicing offerId={offer._id} />),
                    },
                    {
                      showIf: !offer.sentToWarehouse.status,
                      label: "Šalji samo u magacin",
                      action: () =>
                        handleModal(
                          <SendToInvoicing
                            offerId={offer._id}
                            toWarehouse={true}
                          />
                        ),
                    },
                    {
                      label: "Obriši ponudu",
                      action: () => handleRemoveOffer(offer._id),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default OffersTable;
