import { useQuery } from "@apollo/client";
import React from "react";
import { FaCaretLeft } from "react-icons/fa";
import styled from "styled-components";
import GET_USER from "../../apollo/queries/getUser";
import GET_USER_PRICES from "../../apollo/queries/getUserPrices";
import IconText from "../../components/elements/IconText/IconText";
import Spinner from "../../components/elements/Spinner/Spinner";
import Tabs, { Tab } from "../../components/elements/Tabs/Tabs";
import UpdateUser from "../../components/forms/UpdateUser";
import UserPricesTable from "./UserDetails/UserPricesTable";

const Wrapper = styled.div``;

const UserDetails = ({ match, history }) => {
  const { userId } = match.params;

  const { loading: loadingUser, data: { getUser } = {} } = useQuery(GET_USER, {
    variables: { userId },
  });

  const { loading, data: { getUserPrices } = {} } = useQuery(GET_USER_PRICES, {
    variables: { userId },
  });

  // TODO: Price editing over modal, when user clicks to open modal and load current prices as well as the default prices

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <IconText
        onClick={history.goBack}
        text="Idi nazad"
        icon={FaCaretLeft}
        hoverable
      />
      <Tabs container="md">
        <Tab title="Korisnički podaci">
          <UpdateUser
            populateFields={getUser}
            userId={userId}
            loading={loadingUser}
          />
        </Tab>
        <Tab title="Cijene">
          <UserPricesTable
            data={getUserPrices}
            loading={loading}
            userId={userId}
          />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default UserDetails;
