import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import UPDATE_PRICES_FROM_PRICELIST from "../../../apollo/mutations/updatePricesFromPriceList";
import GET_PRICE_LISTS from "../../../apollo/queries/getPriceLists";
import { ModalContext } from "../../../context/modalContext";
import Button from "../../elements/form/Button/Button";
import FormField from "../../elements/form/FormField/FormField";
import FormHeader from "../../elements/form/FormHeader/FormHeader";
import Label from "../../elements/form/Label/Label";
import Select from "../../elements/form/Select/Select";
import Notice from "../../elements/Notice/Notice";
import Distributor from "../../structure/Distributor/Distributor";

const UpdatePrices = ({ userId }) => {
  const { handleModal } = useContext(ModalContext);
  const { data: { getPriceLists } = {}, loading } = useQuery(GET_PRICE_LISTS);

  const [updatePricesFromPriceListMutation, { loading: loadingUpdate }] =
    useMutation(UPDATE_PRICES_FROM_PRICELIST, {
      onCompleted: () => {
        toast.success("Cijene ažurirane");
        handleModal();
      },
      onError: () => toast.error("Greška pri ažuriranju cijena"),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitHandler = ({ priceListId }) => {
    if (window.confirm("Da li ste sigurni?")) {
      updatePricesFromPriceListMutation({
        variables: { priceListId, userId },
        refetchQueries: ["getUserPrices"],
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormHeader>Učitavanje cjenovnika</FormHeader>
      <FormField>
        <Label htmlFor="priceList" error={errors.priceList?.message}>
          Učitaj cijene s cjenovnika
        </Label>
        <Select
          {...register("priceListId", { required: true })}
          loading={loading}
        >
          {getPriceLists?.map((pl) => (
            <option key={pl._id} value={pl._id}>
              {pl.name} ({pl.prices.length})
            </option>
          ))}
        </Select>
      </FormField>
      <Notice type="warning" title="Učitavanje cijena">
        Učitavanjem ovog cjenovnika prepisat ćete preko postojećih cijena te je
        <br />
        vraćanje na staro stanje nemoguće
      </Notice>
      <Distributor stack="end" submitButtons>
        <Button type="submit" layout="round" loading={loading}>
          Učitaj
        </Button>
      </Distributor>
    </form>
  );
};

export default UpdatePrices;
