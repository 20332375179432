import gql from "graphql-tag";

const GET_PRODUCT = gql`
  query getProduct($productId: ID!) {
    getProduct(productId: $productId) {
      sortingIndex
      name
      category {
        name
        image
        _id
      }
      inventoryTag
      attributes {
        thickness
        dimensions {
          _id
          sheetWidth
          sheetHeight
          stockQuantity
          lowStockLevelIndicator
        }
      }
      _id
      price {
        regularPrice
        casePrice
        truckPrice
      }
      image
    }
  }
`;

export default GET_PRODUCT;
