import React, { useContext, useState } from "react";
import QrReader from "react-qr-reader";
import styled from "styled-components";
import { ModalContext } from "../../../context/modalContext";
import Notice from "../Notice/Notice";

const Wrapper = styled.div``;

const QRScan = ({ onScan }) => {
  const [error, setError] = useState(null);

  const { handleModal } = useContext(ModalContext);

  const handleError = (error) => {
    setError(error);
  };

  const handleScan = (data) => {
    if (data) {
      onScan(data);
      handleModal();
    }
  };

  return !error ? (
    <Wrapper>
      <QrReader
        delay={300}
        onScan={handleScan}
        onError={handleError}
        style={{ width: "100%" }}
        resolution={600}
      />
    </Wrapper>
  ) : (
    <Notice type="warning" title="Greška" showIf={true}>
      Greška na QR čitaču, provjerite da li je kamera upaljena
    </Notice>
  );
};

export default QRScan;
