import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const TopControls = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default TopControls;
