import gql from "graphql-tag";

const CREATE_PRODUCT_CATEGORY = gql`
  mutation createProductCategory(
    $categoryId: ID
    $name: String!
    $image: String
  ) {
    createProductCategory(categoryId: $categoryId, name: $name, image: $image)
  }
`;

export default CREATE_PRODUCT_CATEGORY;
