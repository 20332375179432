import gql from "graphql-tag";

const GET_DRIVERS = gql`
  query getDrivers {
    getDrivers {
      _id
      firstName
      lastName
      phoneNumber
      address
    }
  }
`;

export default GET_DRIVERS;
