import React, { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import GET_PRODUCTS from "../../../apollo/queries/getProducts";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import { useHistory, useLocation } from "react-router";
import TopControls from "../../../components/structure/TopControls/TopControls";
import Button from "../../../components/elements/form/Button/Button";
import { FaPlus } from "react-icons/fa";
import CreateProduct from "../../../components/forms/CreateProduct.form";
import { ModalContext } from "../../../context/modalContext";
import REMOVE_PRODUCT from "../../../apollo/mutations/removeProduct";
import { toast } from "react-toastify";
import Spinner from "../../../components/elements/Spinner/Spinner";
import { priceWithoutCurrency } from "../../../helpers/various";
import UPDATE_PRODUCT_BASIC_INFO from "../../../apollo/mutations/updateProductBasicInfo";

const ProductsTable = () => {
  const { handleModal } = useContext(ModalContext);

  const [updatePrice, { loading: loadingUpdatePrice }] = useMutation(
    UPDATE_PRODUCT_BASIC_INFO,
    {
      onCompleted: () => {
        console.log("Saved!");
      },
      onError: () => {
        console.log("Not saved");
      },
    }
  );

  const handleAddProduct = () => {
    handleModal(<CreateProduct />);
  };

  const handleSaveCasePrice = async (value, data) => {
    console.log(value, data);
    return updatePrice({
      variables: {
        productId: data._id,
        casePrice: parseFloat(value),
        category: data.category._id,
      },
    });
  };

  const handleSaveRegularPrice = async (value, data) => {
    console.log(value, data);
    return updatePrice({
      variables: {
        productId: data._id,
        regularPrice: parseFloat(value),
        category: data.category._id,
      },
    });
  };

  const history = useHistory();
  const { pathname } = useLocation();

  const { loading, data: { getProducts } = {}, error } = useQuery(GET_PRODUCTS);
  const [removeProductMutation] = useMutation(REMOVE_PRODUCT, {
    onCompleted: () => {
      toast.success("Proizvod uklonjen");
    },
    onError: () => toast.error("Nemoguće obrisati proizvod"),
  });

  const handleRemoveProduct = (productId, productName) => {
    if (
      prompt(
        `Da li ste sigurni da želite obrisati "${productName}"? Upišite POTVRDA za brisanje. Ova operacija je izuzetno opasna jer je proizvod referenciran u ponudama, narudžbama i sl. i proces je ireverzibilan`
      ) === "POTVRDA"
    ) {
      removeProductMutation({
        variables: {
          productId,
        },
        refetchQueries: ["getProducts"],
      });
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          icon={FaPlus}
          onClick={handleAddProduct}
        >
          Dodaj proizvod
        </Button>
      </TopControls>
      <Table loading={loading}>
        <TableHead>
          <TableRow>
            <TableHeader>Indeks</TableHeader>
            <TableHeader>Naziv proizvoda</TableHeader>
            <TableHeader>Debljina (mm)</TableHeader>
            <TableHeader>Kategorija proizvoda</TableHeader>
            <TableHeader>Inventurni broj</TableHeader>
            <TableHeader>Paleta</TableHeader>
            <TableHeader>Ploča</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getProducts?.map((item) => (
            <TableRow key={item._id}>
              <TableCell>{item.sortingIndex || null}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item?.attributes?.thickness}</TableCell>
              <TableCell>{item.category.name}</TableCell>
              <TableCell>{item.inventoryTag}</TableCell>
              <TableCell
                editable
                onEditSave={handleSaveCasePrice}
                data={item}
                loading={loadingUpdatePrice}
              >
                {priceWithoutCurrency(item.price.casePrice)}
              </TableCell>
              <TableCell
                editable
                onEditSave={handleSaveRegularPrice}
                data={item}
                loading={loadingUpdatePrice}
              >
                {priceWithoutCurrency(item.price.regularPrice)}
              </TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={item._id}
                  actions={[
                    {
                      label: "Uredi proizvod",
                      action: () => history.push(`${pathname}/${item._id}`),
                    },
                    {
                      label: "Obriši proizvod",
                      action: () => handleRemoveProduct(item._id, item.name),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ProductsTable;
