import React, { useState } from "react";
import styled from "styled-components";
import Button from "../form/Button/Button";
import Input from "../form/Input/Input";
import Spinner from "../Spinner/Spinner";
import { FaSave } from "react-icons/fa";

const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: ${({ layout }) => (layout ? layout : "auto")};
  font-size: 0.9rem;
  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.albino};
  }
`;
const TableRow = styled.tr`
  ${({ borderTop, theme }) =>
    borderTop && `border-top: ${borderTop} solid ${theme.colors.almostAlbino}`};

  ${({ noStripe, theme }) =>
    noStripe &&
    `
  background-color: ${theme.colors.white}!important;
  :hover {
    background-color: ${theme.colors.white}!important;
    color: ${theme.colors.black}!important;
  }
`};
`;
const TableCellWrapper = styled.td`
  text-align: left;
  img {
    border-radius: 50%;
    overflow: hidden;
    height: 50px;
    width: 50px;
    object-fit: cover;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 13%);
  }
  input {
    background: none;
    height: 100%;
  }

  ${({ tinted, theme }) =>
    tinted &&
    `
    background-color: ${theme.colors.albino};
  `}

  ${({ highlighted, theme }) =>
    highlighted &&
    `
    background-color: ${theme.colors.red};
    color: ${theme.colors.white};
  `}
`;

const InputCellWrapper = styled.div`
  display: inline-flex;
`;

const ContentWrapper = styled.div`
  padding: 0.5em 0.7rem;
`;

const TableCell = ({ children, editable, onEditSave, data, loading }) => {
  const [value, setValue] = useState(children);
  const [disabled, setDisabled] = useState(true);

  const handleClick = () => {
    setDisabled(false);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSaveEdit = async () => {
    await onEditSave(value, data);
    setDisabled(true);
  };

  return (
    <TableCellWrapper onClick={handleClick}>
      {editable ? (
        <InputCellWrapper>
          <Input value={value} onChange={handleChange} disabled={disabled} />
          {!disabled ? (
            <Button
              color="hollow"
              predefinedSize="extraSmall"
              layout="round"
              icon={FaSave}
              iconSize={14}
              onClick={handleSaveEdit}
              loading={loading}
            />
          ) : null}
        </InputCellWrapper>
      ) : (
        <ContentWrapper>{children}</ContentWrapper>
      )}
    </TableCellWrapper>
  );
};

const TableBody = styled.tbody`
  tr:hover {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    td input {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
const TableHead = styled.thead`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 600;
  tr {
    box-shadow: 0 2px ${({ theme }) => theme.colors.almostAlbino};
    background: ${({ theme }) => theme.colors.white};
    position: sticky;
    top: 0;
    z-index: 9;
  }
`;
const TableHeader = styled.th`
  text-align: left;
  padding: 0.8rem;
`;
const ActionCell = styled.td``;
const ActionHeader = styled.th``;

const Table = ({ loading, children, ...otherProps }) => {
  return loading ? <Spinner /> : <Wrapper {...otherProps}>{children}</Wrapper>;
};

export {
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableHeader,
  ActionCell,
  ActionHeader,
  Table as default,
};
