import React, { useContext } from "react";
import { FaTrash } from "react-icons/fa";
import styled from "styled-components";
import { Context as CartContext } from "../../../context/cartContext";
import { price } from "../../../helpers/various";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
`;

const Product = styled.div`
  flex: 7;
`;
const Name = styled.div`
  font-weight: 600;
`;
const Details = styled.div`
  font-size: 0.8rem;
`;
const Quantity = styled.div`
  background-color: ${({ theme }) => theme.colors.almostWhite};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 0.8rem;
  flex: 1;
  justify-content: center;
  display: flex;
`;
const Price = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const Total = styled.div``;
const RemoveItem = styled.div`
  margin-left: 1rem;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  color: ${({ theme }) => theme.colors.lightGrey};
  :hover {
    color: ${({ theme }) => theme.colors.red};
    cursor: pointer;
  }
`;

const CartItem = ({
  removeId,
  data: { data, surface, dimension, quantity, totalPrice },
}) => {
  const { removeFromCart } = useContext(CartContext);
  const handleRemoveFromCart = () => {
    removeFromCart(removeId);
  };

  return (
    <Wrapper>
      <Product>
        <Name>{data.name}</Name>
        <Details>{dimension}</Details>
        <Details>{data.attributes.thickness}mm</Details>
      </Product>
      <Quantity>{quantity}</Quantity>
      <Price>
        <Total>{price(totalPrice)}</Total>
        <Details>
          {surface?.toFixed(2)}m<sup>2</sup>
        </Details>
      </Price>
      <RemoveItem onClick={handleRemoveFromCart}>
        <FaTrash />
      </RemoveItem>
    </Wrapper>
  );
};

export default CartItem;
