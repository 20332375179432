import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import Textarea from "../elements/form/Textarea/Textarea";
import Distributor from "../structure/Distributor/Distributor";
import { sendToInvoicingSchema, sendToWarehouseSchema } from "./schema";
import SEND_TO_INVOICING from "../../apollo/mutations/sendToInvoicing";
import GET_DRIVERS from "../../apollo/queries/getDrivers";
import GET_TRUCKS from "../../apollo/queries/getTrucks";
import Select from "../elements/form/Select/Select";
import { PAYMENT_METHODS } from "../../constants/constants";
import dayjs from "dayjs";
import { ModalContext } from "../../context/modalContext";
import { pickBy } from "lodash";

const SendToInvoicing = ({ offerId, toWarehouse }) => {
  const resolver = toWarehouse ? sendToWarehouseSchema : sendToInvoicingSchema;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resolver),
  });

  const { handleModal } = useContext(ModalContext);

  const [sendToInvoicingMutation, { loading }] = useMutation(
    SEND_TO_INVOICING,
    {
      onCompleted: () => {
        handleModal();
        toast.success("Poslano na fakturisanje i prebačeno u narudžbenice");
      },
      onError: (e) =>
        toast.error(
          `Operacija nije uspjela, provjerite da li je već proslijeđeno na navedenu službu`
        ),
    }
  );

  const { data: { getDrivers } = {}, loading: loadingDrivers } =
    useQuery(GET_DRIVERS);
  const { data: { getTrucks } = {}, loading: loadingTrucks } =
    useQuery(GET_TRUCKS);

  const handleSendToInvoicing = (values) => {
    console.log(values);
    const invoiceDate = values?.invoiceDate
      ? dayjs(values.invoiceDate).format("YYYY-MM-DD")
      : null;
    const deliveryDate = values?.deliveryDate
      ? dayjs(values.deliveryDate).format("YYYY-MM-DD")
      : null;

    const orderInfo = pickBy(
      {
        ...values,
        invoiceDate,
        deliveryDate,
      },
      (val) => val !== null
    );

    console.log(orderInfo);

    sendToInvoicingMutation({
      variables: {
        offerId,
        orderInfo,
        toWarehouse,
      },
      refetchQueries: ["getOffers", "getOrders"],
    });
  };

  return (
    <form onSubmit={handleSubmit(handleSendToInvoicing)}>
      <FormHeader>
        Šalji {toWarehouse ? "u magacin" : "na fakturisanje"}
      </FormHeader>
      <FormField>
        <Label htmlFor="driver" error={errors.driver?.message}>
          Vozač
        </Label>
        <Select {...register("driver")} loading={loadingDrivers}>
          {getDrivers?.map((driver) => (
            <option key={driver._id} value={driver._id}>
              {driver.firstName} {driver.lastName}
            </option>
          ))}
        </Select>
      </FormField>
      <FormField>
        <Label htmlFor="truck" error={errors.truck?.message}>
          Kamion
        </Label>
        <Select {...register("truck")} loading={loadingTrucks}>
          {getTrucks?.map((truck) => (
            <option key={truck._id} value={truck._id}>
              {truck.truck}
            </option>
          ))}
        </Select>
      </FormField>
      <Distributor col="1-1">
        {toWarehouse ? (
          <FormField key="1">
            <Label htmlFor="deliveryDate" error={errors.deliveryDate?.message}>
              Datum isporuke
            </Label>
            <Input
              type="date"
              {...register("deliveryDate", { valueAsDate: true })}
            />
          </FormField>
        ) : (
          <FormField key="2">
            <Label htmlFor="invoiceDate" error={errors.invoiceDate?.message}>
              Datum fakturisanja
            </Label>
            <Input
              type="date"
              {...register("invoiceDate", { valueAsDate: true })}
            />
          </FormField>
        )}
        <FormField>
          <Label htmlFor="paymentMethod" error={errors.paymentMethod?.message}>
            Metoda plaćanja
          </Label>
          <Select {...register("paymentMethod")}>
            {Object.keys(PAYMENT_METHODS).map((method) => (
              <option key={method} value={method}>
                {PAYMENT_METHODS[method]}
              </option>
            ))}
          </Select>
        </FormField>
      </Distributor>
      {toWarehouse ? (
        <FormField>
          <Label htmlFor="note" error={errors.sentToWarehouse?.note?.message}>
            Napomena za skladište
          </Label>
          <Textarea rows="3" {...register("sentToWarehouse.note")} />
        </FormField>
      ) : (
        <FormField>
          <Label htmlFor="note" error={errors.sentToInvoicing?.note?.message}>
            Napomena za fakturisanje
          </Label>
          <Textarea rows="3" {...register("sentToInvoicing.note")} />
        </FormField>
      )}

      <Distributor stack="end" submitButtons>
        <Button layout="round" loading={loading}>
          Pošalji
        </Button>
      </Distributor>
    </form>
  );
};

export default SendToInvoicing;
