import React from "react";
import styled from "styled-components";
import Tabs, { Tab } from "../../components/elements/Tabs/Tabs";
import DriversTable from "./Logistics/DriversTable";
import TrucksTable from "./Logistics/TrucksTable";

const Wrapper = styled.div``;

const Logistics = () => {
  return (
    <Wrapper>
      <Tabs container="md">
        <Tab title="Vozači">
          <DriversTable />
        </Tab>
        <Tab title="Kamioni">
          <TrucksTable />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default Logistics;
