import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Container from "../../structure/Container/Container";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const TabsWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.colors.almostAlbino};
`;
const Content = styled.div`
  padding-top: 2rem;
`;
const Tab = styled.div`
  border-radius: 5px 5px 0 0;
  padding: 0.6rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 600;

  ${({ active, theme }) =>
    active &&
    `
  border-top: 4px solid ${theme.colors.red};
  background-color: ${theme.colors.lightestGrey};
  `};
  :hover {
    cursor: pointer;
    ${({ theme, active }) =>
      !active &&
      `
        background-color: ${theme.colors.red};
        color: ${theme.colors.white};
    `}
  }
`;

const Tabs = ({ children, container }) => {
  const [content, setContent] = useState(null);
  const [active, setActive] = useState(0);

  useEffect(() => {
    setContent(children[active].props.children);
  }, [active, children]);

  return (
    <Wrapper>
      <TabsWrapper>
        {Array.isArray(children) ? (
          children.map((child, i) => (
            <Tab key={i} onClick={() => setActive(i)} active={active === i}>
              {child.props.title}
            </Tab>
          ))
        ) : (
          <Tab>{children.props.title}</Tab>
        )}
      </TabsWrapper>
      <Container size={container}>
        <Content>{content}</Content>
      </Container>
    </Wrapper>
  );
};

export { Tab, Tabs as default };
