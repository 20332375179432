import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router";
import { Context as CurrentUserContext } from "../../../context/currentUser";

const ProtectedRoute = ({ component: Component, roles, ...otherProps }) => {
  const {
    logout,
    reauthenticate,
    state: { authorized },
  } = useContext(CurrentUserContext);

  useEffect(() => {
    // if not authorized try to reauthenticated from token
    if (!authorized) {
      reauthenticate();
    }
  }, [authorized]);

  return (
    <Route
      {...otherProps}
      render={(props) =>
        auth.isAuthenticated(roles) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const auth = {
  isAuthenticated(roles) {
    const token = localStorage.getItem("user");
    if (token) {
      const {
        user: { role },
      } = JSON.parse(token);

      if (role === "admin") return true;

      if (roles) {
        if (roles.indexOf(role.toLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
};

export default ProtectedRoute;
