import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import DynamicTable from "../../../components/elements/DynamicTable/DynamicTable";
import Notice from "../../../components/elements/Notice/Notice";
import Spinner from "../../../components/elements/Spinner/Spinner";
import Space from "../../../components/structure/Space/Space";
import { Context as OfferContext } from "../../../context/offerContext";

const Wrapper = styled.div``;
const Heading = styled.div``;

const NewOfferTable = ({ data }) => {
  const { setProducts } = useContext(OfferContext);

  useEffect(() => {
    if (data) {
      setProducts(data);
    }
  }, [data]);

  return data ? (
    <Wrapper>
      <Notice
        type="warning"
        title="Slanje ponude"
        showIf={!data[0].user.contactInfo.email}
      >
        Odabrani korisnik nema navedenu email adresu stoga neće biti moguće
        isporučiti ponudu. Dodajte email prije generisanja ponude
      </Notice>
      <Heading>
        Pravite ponudu za korisnika: <strong>{data[0].user.username}</strong>
      </Heading>
      <Space vertical="4rem" />
      <DynamicTable />
    </Wrapper>
  ) : (
    <Spinner />
  );
};

export default NewOfferTable;
