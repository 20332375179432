import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import Input from "../elements/form/Input/Input";
import Spinner from "../elements/Spinner/Spinner";

const Wrapper = styled.div`
  position: relative;
`;
const ResultsWrapper = styled.div`
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  width: 100%;
  padding: 10px 0;
  box-shadow: 0 5px 4px 0px rgb(0 0 0 / 10%);
`;
const ResultItemWrapper = styled.div`
  padding: 0.7rem 1rem;
  display: block;
  :hover {
    background-color: ${({ theme }) => theme.colors.albino};
    cursor: pointer;
  }
`;

const Results = ({ render, input, options, compare }) => {
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (input?.length) {
      setSearchResults(
        options.filter((option) =>
          option[compare].toLowerCase().includes(input.toLowerCase())
        )
      );
    } else {
      setSearchResults([]);
    }
  }, [input, options, compare]);

  return render({
    searchResults,
    setSearchResults,
  });
};

const ResultItem = ({ children, ...otherProps }) => {
  return <ResultItemWrapper {...otherProps}>{children}</ResultItemWrapper>;
};

const AutosuggestInput = React.forwardRef(
  (
    {
      populateWith,
      loading,
      labelField,
      compareField,
      valueField,
      onSelect,
      ...otherProps
    },
    ref
  ) => {
    return loading ? (
      <Spinner />
    ) : (
      <Controller
        render={({ field: { value, ...otherFieldProps } }) => (
          <Wrapper>
            <Input
              {...otherFieldProps}
              value={value || ""}
              ref={ref}
              autoComplete="off"
            />
            <Results
              input={value}
              compare={compareField}
              options={populateWith}
              render={({ searchResults, setSearchResults }) => {
                return searchResults?.length > 0 ? (
                  <ResultsWrapper>
                    {searchResults.map((result) => (
                      <ResultItem
                        key={result[valueField]}
                        onClick={() => {
                          onSelect(result[valueField], result[compareField]);
                          setSearchResults([]);
                        }}
                      >
                        {result[labelField]}
                      </ResultItem>
                    ))}
                  </ResultsWrapper>
                ) : null;
              }}
            />
          </Wrapper>
        )}
        {...otherProps}
      />
    );
  }
);

export default AutosuggestInput;
