import React from "react";
import styled from "styled-components";
import Spinner from "../Spinner/Spinner";
import SearchResultItem from "./SearchResultItem";

const Wrapper = styled.div`
  margin-top: 40px;
`;

const SearchResultsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

const SearchResults = ({ loading, data }) => {
  if (!data?.length && !loading) return <Wrapper>Nema rezultata</Wrapper>;

  return (
    <Wrapper>
      {loading ? (
        <Spinner />
      ) : (
        <SearchResultsGrid>
          {data?.map((item) => (
            <SearchResultItem key={item._id} data={item} />
          ))}
        </SearchResultsGrid>
      )}
    </Wrapper>
  );
};

export default SearchResults;
