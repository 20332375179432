const theme = {
  fonts: {
    body: "Open Sans",
    heading: "Kumbh Sans",
  },
  colors: {
    darkBlue: "#082e4c",
    blue: "#0063B0",
    lightBlue: "#8FB2F9",
    fadingBlue: "#f0f6fa",
    greyBlue: "#d0dde5",
    white: "#FFF",
    almostWhite: "#efefef",
    albino: "#f7f7f7",
    almostAlbino: "#e9e9e9",
    red: "#FF005E",
    albinoRed: "#ffeded",
    black: "#151515",
    almostBlack: "#4d4d4e",
    grey: "#91959B",
    lightGrey: "#C9CBCE",
    lighterGrey: "#E5E5E5",
    lightestGrey: "#F5F5F5",
    albinoYello: "#fffcf0",
    darkYello: "#ffc800",
    yello: "#ffc800",
  },
};

export default theme;
