import React from "react";
import styled from "styled-components";
import Space from "../structure/Space/Space";
import BasicUserInfoSection from "./UpdateUser/BasicUserInfoSection";
import ChangePasswordSection from "./UpdateUser/ChangePasswordSection";

const Wrapper = styled.div``;

const UpdateUser = ({ populateFields, userId }) => {
  return (
    <Wrapper>
      <BasicUserInfoSection populateFields={populateFields} userId={userId} />
      <Space vertical="4rem" />
      <ChangePasswordSection userId={userId} />
    </Wrapper>
  );
};

export default UpdateUser;
