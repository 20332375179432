import React from "react";
import styled from "styled-components";
import StockList from "./Stock/StockList";

const Wrapper = styled.div``;

const Stock = () => {
  return (
    <Wrapper>
      <StockList />
    </Wrapper>
  );
};

export default Stock;
