import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HTTP_LINK,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    localStorage.removeItem("user");
  }
});

const authLink = setContext((_, { headers }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return {
    headers: {
      ...headers,
      authorization: user ? user.token : "",
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      StockItem: {
        keyFields: ["_id", "dimensions", ["_id"]],
      },
    },
  }),
  connectToDevTools: process.env.NODE_ENV === "development" && true,
});

export default client;
