import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.nav`
  display: flex;

  a {
    display: block;
    position: relative;
  }

  a.active {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 700;
  }

  a.active:after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.colors.red};
  }

  a:hover {
    color: ${({ theme }) => theme.colors.blue};
  }

  a:not(:last-child) {
    margin-right: 2rem;
  }
`;

const Navigation = () => {
  return (
    <Wrapper>
      <NavLink exact to="/">
        Naslovna
      </NavLink>
      <NavLink to="/about-us">O nama</NavLink>
      <NavLink to="/references">Reference</NavLink>
      <NavLink to="/contact">Kontakt</NavLink>
    </Wrapper>
  );
};

export default Navigation;
