import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import CREATE_PRODUCT_CATEGORY from "../../apollo/mutations/createProductCategory";
import { FOLDERS } from "../../constants/constants";
import { ModalContext } from "../../context/modalContext";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import UploadFiles from "../elements/UploadFiles/UploadFiles";
import Distributor from "../structure/Distributor/Distributor";
import { productCategorySchema } from "./schema";

const CreateProductCategory = ({ populateFields, categoryId, noTitle }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [files, setFiles] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const { handleModal } = useContext(ModalContext);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productCategorySchema),
  });

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT_CATEGORY, {
    onCompleted: () => {
      toast.success("Kategorija dodana");
      handleModal();
    },
    onError: () => toast.error("Nije moguće dodati kategoriju"),
  });

  const onSubmitHandler = ({ name }) => {
    createProduct({
      variables: {
        categoryId,
        name,
        image: uploaded[0],
      },
      refetchQueries: ["getProductCategories"],
    });
  };

  const handleUploadedFiles = (newFile) => {
    setUploaded([newFile]);
  };

  useEffect(() => {
    if (populateFields) {
      setValue("name", populateFields.name);
      setUploaded([populateFields.image].filter(Boolean));
    }
  }, [populateFields]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      {!noTitle ? <FormHeader>Dodaj kategoriju</FormHeader> : null}
      <FormField>
        <Label htmlFor="image">Slika kategorije</Label>
        <UploadFiles
          uploaded={uploaded}
          onFileUpload={handleUploadedFiles}
          onSelect={setFiles}
          files={files}
          uploadFolder={FOLDERS.productCategories}
        />
      </FormField>
      <FormField>
        <Label htmlFor="name" error={errors?.name?.message}>
          Naziv kategorije proizvoda
        </Label>
        <Input name="name" {...register("name")} />
      </FormField>

      <Distributor stack="end" submitButtons>
        <Button layout="round">Sačuvaj proizvod</Button>
      </Distributor>
    </form>
  );
};

export default CreateProductCategory;
