import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import styled, { useTheme } from "styled-components";
import IconText from "../../elements/IconText/IconText";

const Wrapper = styled.div`
  > *:not(:last-child) {
    margin-right: 3rem;
  }
`;

const Socials = () => {
  const theme = useTheme();
  return (
    <Wrapper>
      <IconText
        icon={FaPhone}
        text="+38761 166 669"
        iconColor={theme.colors.lightBlue}
        url="tel:0038761166669"
      />
      <IconText
        icon={FaPhone}
        text="+38735 652 274"
        iconColor={theme.colors.lightBlue}
        url="tel:0038735652274"
      />
      <IconText
        icon={FaEnvelope}
        text="info@imperialglass.ba"
        iconColor={theme.colors.lightBlue}
        url="mailto:info@imperialglass.ba"
      />
    </Wrapper>
  );
};

export default Socials;
