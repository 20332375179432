import React, { useContext } from "react";
import ButtonDropdown from "../../components/elements/ButtonDropdown/ButtonDropdown";
import Notice from "../../components/elements/Notice/Notice";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/elements/Table/Table";
import Space from "../../components/structure/Space/Space";
import { Context as CartContext } from "../../context/cartContext";
import { price } from "../../helpers/various";

const OrderTable = () => {
  const {
    removeFromCart,
    state: { cart },
  } = useContext(CartContext);

  return cart?.length ? (
    <>
      <h2>Narudžbenica</h2>
      <Space vertical="2rem" />
      <Table>
        <TableHead>
          <TableHeader>Proizvod</TableHeader>
          <TableHeader>Dimenzija</TableHeader>
          <TableHeader>Količina</TableHeader>
          <TableHeader>Površina</TableHeader>
          <TableHeader>Cijena</TableHeader>
          <TableHeader>Ukupno</TableHeader>
          <ActionHeader></ActionHeader>
        </TableHead>
        <TableBody>
          {cart.map((cartItem) => (
            <TableRow key={cartItem.id}>
              <TableCell>{cartItem.data.name}</TableCell>
              <TableCell>{cartItem.dimension}</TableCell>
              <TableCell>{cartItem.quantity}</TableCell>
              <TableCell>{cartItem.surface}</TableCell>
              <TableCell>{price(cartItem.data.price.regularPrice)}</TableCell>
              <TableCell>{price(cartItem.totalPrice)}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={cartItem.id}
                  actions={[
                    {
                      label: "Ukloni",
                      action: () => removeFromCart(cartItem.id),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  ) : (
    <Notice type="info" title="Korpa je prazna" showIf={true}>
      Korpa je prazna, vratite se na naslovnu da dodate artikle
    </Notice>
  );
};

export default OrderTable;
