import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import styled from "styled-components";
import REMOVE_USER from "../../apollo/mutations/removeUser";
import GET_USERS from "../../apollo/queries/getUsers";
import ButtonDropdown from "../../components/elements/ButtonDropdown/ButtonDropdown";
import Button from "../../components/elements/form/Button/Button";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/elements/Table/Table";
import CreateUser from "../../components/forms/CreateUser.form";
import TopControls from "../../components/structure/TopControls/TopControls";
import { ModalContext } from "../../context/modalContext";

const Wrapper = styled.div``;

const Users = ({ location, history }) => {
  const { handleModal } = useContext(ModalContext);
  const { data: { getUsers } = {}, loading } = useQuery(GET_USERS);
  const [removeUserMutation] = useMutation(REMOVE_USER, {
    onCompleted: () => {
      toast.success("Korisnik obrisan");
    },
    onError: () => toast.error("Nemoguće obrisati korisnika"),
  });
  const { pathname } = location;

  const handleRemoveUser = (userId) => {
    if (
      prompt(
        "Pažljivo! Ovom akcijom ćete obrisati sve cijene, ponude i narudžbe vezane za ovog korisnika. Da biste nastavili napišite POTVRDA"
      ) === "POTVRDA"
    ) {
      removeUserMutation({
        variables: { userId },
        refetchQueries: ["getUsers"],
      });
    }
  };
  return (
    <Wrapper>
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          onClick={() => handleModal(<CreateUser />)}
          icon={FaPlus}
        >
          Dodaj korisnika
        </Button>
      </TopControls>
      <Table loading={loading}>
        <TableHead>
          <TableRow>
            <TableHeader>Korisničko ime</TableHeader>
            <TableHeader>Puno ime</TableHeader>
            <TableHeader>Uloga</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Odobreni limit (KM)</TableHeader>
            <TableHeader>Termin plaćanja (dan)</TableHeader>
            <TableHeader>Dugovanje (KM)</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getUsers?.map((user) => (
            <TableRow key={user._id}>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.fullName}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.contactInfo.email}</TableCell>
              <TableCell>{user.creditLimit.approvedLimit}</TableCell>
              <TableCell>{user.creditLimit.paymentTerm}</TableCell>
              <TableCell>{user.creditLimit.debt}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={user.id}
                  actions={[
                    {
                      label: "Uredi korisnika",
                      action: () => history.push(`${pathname}/${user._id}`),
                    },
                    {
                      showIf: !(user.username === "admin"),
                      icon: <AiOutlineWarning />,
                      label: "Obriši korisnika",
                      action: () => handleRemoveUser(user._id),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default Users;
