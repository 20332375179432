import React from "react";
import { FaPlus } from "react-icons/fa";
import styled from "styled-components";
import Button from "../../components/elements/form/Button/Button";
import Container from "../../components/structure/Container/Container";
import TopControls from "../../components/structure/TopControls/TopControls";
import PriceListsTable from "./PriceLists/PriceListsTable";

const Wrapper = styled.div``;

const PriceLists = ({ history, location }) => {
  const { pathname } = location;
  return (
    <Wrapper>
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          icon={FaPlus}
          onClick={() => history.push(`${pathname}/new`)}
        >
          Napravi cjenovnik
        </Button>
      </TopControls>
      <Container size="md">
        <PriceListsTable />
      </Container>
    </Wrapper>
  );
};

export default PriceLists;
