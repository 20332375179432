import React, { useContext } from "react";
import { FaShoppingCart } from "react-icons/fa";
import styled, { useTheme } from "styled-components";
import { Context as CartContext } from "../../../context/cartContext";
import useWindowSize from "../../../hooks/useWindowSize";
import IconText from "../../elements/IconText/IconText";
import ProtectedContent from "../../security/ProtectedContent/ProtectedContent";
import Container from "../Container/Container";
import Logo from "../Logo/Logo";
import MobileNavigation from "../Navigation/MobileNavigation";
import Navigation from "../Navigation/Navigation";

const Wrapper = styled.div`
  padding: 44px 0;
  position: relative;
  z-index: 3;
  border-bottom: 1px solid ${({ theme }) => theme.colors.almostAlbino};
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const NavWrapper = styled.div``;
const MobileNavWrapper = styled.div`
  display: flex;
`;

const Header = () => {
  const { width } = useWindowSize();
  const theme = useTheme();

  const {
    showCart,
    state: { cart },
  } = useContext(CartContext);

  return (
    <Wrapper>
      <Container size="lg">
        <Logo />
        <NavWrapper>
          {width < 601 ? (
            <MobileNavWrapper>
              <ProtectedContent onlyFor="logged-out">
                <IconText
                  iconColor={theme.colors.red}
                  iconSize="1.5rem"
                  icon={FaShoppingCart}
                  hoverable
                  counter={cart.length}
                  onClick={showCart}
                />
              </ProtectedContent>
              <MobileNavigation
                items={[
                  {
                    title: "Naslovna",
                    url: "/",
                  },
                  {
                    title: "O nama",
                    url: "/about-us",
                  },
                  {
                    title: "Reference",
                    url: "/references",
                  },
                  {
                    title: "Contact",
                    url: "/contact",
                  },
                ]}
              />
            </MobileNavWrapper>
          ) : (
            <Navigation />
          )}
        </NavWrapper>
      </Container>
    </Wrapper>
  );
};

export default Header;
