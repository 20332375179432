import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import styled from "styled-components";
import { Context as CartContext } from "../../../context/cartContext";
import Distributor from "../../structure/Distributor/Distributor";
import Space from "../../structure/Space/Space";
import Button from "../form/Button/Button";
import Input from "../form/Input/Input";
import Select from "../form/Select/Select";

const Wrapper = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  border-radius: 5px;
  :hover {
    border-color: ${({ theme }) => theme.colors.grey};
    cursor: pointer;
  }
`;
const Image = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ exists, theme }) =>
    !exists && `background-color: ${theme.colors.lightestGrey}`};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  margin: 1rem 0;
  font-weight: 500;
`;

const Attributes = styled.table`
  width: 100%;
  font-size: 0.8rem;
  tr {
    td:last-child {
      text-align: right;
    }
  }
`;

const Dimensions = styled.div``;

const SearchResultItem = ({ data }) => {
  const { getValues, setValue, register } = useForm();

  const { addToCart } = useContext(CartContext);

  const handleAddToCart = () => {
    const { dimension, quantity } = getValues();

    // if nothing is selected
    if (!dimension || !quantity) return;

    const [width, height] = dimension.split("x");

    addToCart({
      id: `${data._id}_${dimension}`,
      data,
      dimension,
      quantity: parseInt(quantity),
      totalPrice: parseInt(quantity) * parseFloat(data.price.regularPrice),
      surface:
        (parseInt(quantity) * parseInt(width) * parseInt(height)) / 1000000,
    });
    setValue("dimension", "");
    setValue("quantity", "");
  };

  return (
    <Wrapper>
      <Image exists={data.category?.image}>
        {data.category?.image ? (
          <img
            src={process.env.REACT_APP_AWS_BUCKET + data.category?.image}
            alt="Product"
          />
        ) : (
          "Nema slike"
        )}
      </Image>
      <Name>{data.name}</Name>
      <Attributes>
        <tbody>
          <tr>
            <td>Kategorija</td>
            <td>{data.category.name}</td>
          </tr>
          <tr>
            <td>Oznaka</td>
            <td>{data.inventoryTag || "Nedefinisano"}</td>
          </tr>
          <tr>
            <td>Debljina</td>
            <td>{data.attributes?.thickness || "Nedefinisano"}</td>
          </tr>
          <tr>
            <td>Cijena (ploča)</td>
            <td>{data.price.regularPrice}</td>
          </tr>
        </tbody>
      </Attributes>
      <Space vertical="1rem" />
      <Distributor col="2-1">
        <Dimensions>
          <Select label="Dimenzija" {...register("dimension")}>
            {data.attributes?.dimensions.map((dimension, i) => (
              <option
                key={i}
                value={`${dimension.sheetWidth}x${dimension.sheetHeight}`}
              >{`${dimension.sheetWidth}x${dimension.sheetHeight}`}</option>
            ))}
          </Select>
        </Dimensions>
        <Input
          placeholder="Količina"
          type="number"
          step="1"
          min="1"
          {...register("quantity")}
        />
      </Distributor>
      <Space vertical="1rem" />
      <Button
        icon={FaPlus}
        iconSize={25}
        width="100%"
        layout="round"
        color="secondary"
        onClick={handleAddToCart}
      >
        Dodaj u korpu
      </Button>
    </Wrapper>
  );
};

export default SearchResultItem;
