import gql from "graphql-tag";

const GET_STOCK = gql`
  query getStock {
    getStock {
      _id
      sortingIndex
      name
      inventoryTag
      category
      dimensions {
        _id
        sheetWidth
        sheetHeight
        stockQuantity
        lowStockLevelIndicator
      }
    }
  }
`;

export default GET_STOCK;
