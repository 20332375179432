import gql from "graphql-tag";

const UPDATE_SINGLE_PRICE = gql`
  mutation updateSinglePrice(
    $priceId: ID!
    $regularPrice: Float!
    $casePrice: Float!
  ) {
    updateSinglePrice(
      priceId: $priceId
      regularPrice: $regularPrice
      casePrice: $casePrice
    )
  }
`;

export default UPDATE_SINGLE_PRICE;
