import gql from "graphql-tag";

const GET_PRICE_LIST = gql`
  query getPriceList($priceListId: ID!) {
    getPriceList(priceListId: $priceListId) {
      _id
      name
      prices {
        product {
          _id
          name
        }
        regularPrice
        casePrice
      }
      dateCreated
    }
  }
`;

export default GET_PRICE_LIST;
