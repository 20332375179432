import { useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import {
  FaBell,
  FaBorderAll,
  FaChartBar,
  FaEnvelopeOpenText,
  FaEuroSign,
  FaStreetView,
  FaTruckMoving,
  FaUserFriends,
} from "react-icons/fa";
import { NavLink, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import GET_NOTIFICATIONS from "../../../apollo/queries/getNotifications";
import { Context as GlobalContext } from "../../../context/globalContext";

const Wrapper = styled.div`
  width: 300px;
  background-color: ${({ theme }) => theme.colors.albino};
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
`;

const Count = styled.div`
  position: absolute;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  border-radius: 50%;
`;

const NavItem = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  :hover {
    background-color: ${({ theme }) => theme.colors.almostAlbino};
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.white};
  }
  svg {
    margin-right: 1rem;
  }
`;

const Divider = styled.div`
  width: 80%;
  height: 2px;
  background: ${({ theme }) => theme.colors.almostAlbino};
  margin: 2rem auto;
`;

const AdminNavigation = () => {
  const {
    updateNotifications,
    state: { unreadNotifications },
  } = useContext(GlobalContext);

  const { data: { getNotifications } = {}, loading } = useQuery(
    GET_NOTIFICATIONS,
    {
      onCompleted: ({ getNotifications: data }) => {
        const unread = data.reduce((acc, curr) => !curr.seen && acc + 1, 0);
        if (unread > 0) updateNotifications(unread);
      },
    }
  );

  const { path } = useRouteMatch();
  return (
    <Wrapper>
      <Sticky>
        <NavItem to={`${path}/users`}>
          <FaUserFriends size="20px" />
          Korisnici
        </NavItem>
        <NavItem to={`${path}/products`}>
          <FaBorderAll size="20px" />
          Proizvodi
        </NavItem>
        <NavItem to={`${path}/stock`}>
          <FaChartBar size="20px" />
          Lager
        </NavItem>
        <NavItem to={`${path}/logistics`}>
          <FaTruckMoving size="20px" />
          Logistika
        </NavItem>
        <Divider />
        <NavItem to={`${path}/offers`}>
          <FaEnvelopeOpenText size="20px" />
          Ponude
        </NavItem>
        <NavItem to={`${path}/orders`}>
          <FaChartBar size="20px" />
          Narudžbe
        </NavItem>
        <Divider />
        <NavItem to={`${path}/map`}>
          <FaStreetView size="20px" />
          Mapa
        </NavItem>
        <NavItem to={`${path}/price-lists`}>
          <FaEuroSign size="20px" />
          Cjenovnici
        </NavItem>
        <NavItem to={`${path}/notifications`}>
          <FaBell size="20px" />
          Notifikacije
          {!loading && unreadNotifications > 0 ? (
            <Count>{unreadNotifications}</Count>
          ) : null}
        </NavItem>
      </Sticky>
    </Wrapper>
  );
};

export default AdminNavigation;
