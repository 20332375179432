import React from "react";
import styled from "styled-components";
import Container from "../components/structure/Container/Container";
import Space from "../components/structure/Space/Space";
import OrderTable from "./Order/OrderTable";
import Totals from "./Order/Totals";

const Wrapper = styled.div`
  padding: 40px;
`;

const Order = () => {
  return (
    <Wrapper>
      <Container size="md">
        <OrderTable />
        <Space vertical="2rem" />
        <Totals />
      </Container>
    </Wrapper>
  );
};

export default Order;
