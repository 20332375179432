import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { ModalContext } from "../../context/modalContext";
import Button from "../elements/form/Button/Button";
import Distributor from "../structure/Distributor/Distributor";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import FormField from "../elements/form/FormField/FormField";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import Space from "../structure/Space/Space";
import Spinner from "../elements/Spinner/Spinner";

const Wrapper = styled.div``;
const Map = styled.div``;

const ChooseLocation = () => {
  const { handleModal } = useContext(ModalContext);
  const { data, setData } = useContext(ModalContext);
  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [location, setLocation] = useState(() => {
    if (data?.address?.location) {
      return data.address.location;
    }
    return "";
  });
  const [center, setCenter] = useState(() => {
    if (data?.address?.geo_lon && data?.address?.geo_lat) {
      return {
        lat: parseFloat(data?.address?.geo_lat),
        lng: parseFloat(data?.address?.geo_lon),
      };
    }
    return { lat: 44.194797, lng: 17.909635 };
  });

  console.log(data);

  const { isLoaded } = useJsApiLoader({
    id: "google-maps",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const onLoad = useCallback(function callback(map) {
    setGeocoder(new window.google.maps.Geocoder());
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handlePositionChanged = async (e) => {
    const latLng = e.latLng;
    const location = await geocoder.geocode({ latLng: latLng });
    setLocation(location?.results[0].formatted_address);
    setCenter({ lat: latLng.lat(), lng: latLng.lng() });
  };

  const handleSelectLocation = () => {
    setData({
      ...data,
      address: { location, geo_lon: center.lng, geo_lat: center.lat },
    });
    handleModal();
  };

  return (
    <Wrapper>
      <Map>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker
              draggable
              onDragEnd={handlePositionChanged}
              key={1}
              position={center}
            />
          </GoogleMap>
        ) : (
          <Spinner />
        )}
      </Map>
      <Space vertical="2rem" />
      <Distributor col="5-1" align="end">
        <FormField>
          <Label htmlFor="location">Lokacija (adresa)</Label>
          <Input readOnly value={location} />
        </FormField>
        <FormField>
          <Button
            color="secondary"
            layout="round"
            width="100%"
            onClick={handleSelectLocation}
          >
            Odaberi lokaciju
          </Button>
        </FormField>
      </Distributor>
    </Wrapper>
  );
};

export default ChooseLocation;
