import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPlus, FaQrcode } from "react-icons/fa";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ModalContext } from "../../../context/modalContext";
import { Context as OfferContext } from "../../../context/offerContext";
import Distributor from "../../structure/Distributor/Distributor";
import DynamicInput from "../DynamicInput/DynamicInput";
import Button from "../form/Button/Button";
import FormField from "../form/FormField/FormField";
import Input from "../form/Input/Input";
import Label from "../form/Label/Label";
import Select from "../form/Select/Select";
import QRScan from "./QRScan";

const Wrapper = styled.div`
  input,
  button,
  select {
    height: 50px;
  }
`;

const DynamicRow = () => {
  const [selectedPrice, setSelectedPrice] = useState(0);
  const { register, setValue, watch, getValues } = useForm();
  const { handleModal } = useContext(ModalContext);
  const {
    state: { selectedProduct, products },
    setProduct,
    addToOffer,
  } = useContext(OfferContext);

  useEffect(() => {
    if (!isEmpty(selectedProduct)) {
      setValue("regularPrice", selectedProduct.price.regularPrice);
      setValue("casePrice", selectedProduct.price.casePrice);
    } else {
      setValue("regularPrice", 0);
      setValue("casePrice", 0);
      setValue("surface", 0);
      setValue("quantity", 0);
    }
  }, [selectedProduct]);

  const watchChange = watch(["quantity", "dimension"]);

  // input watcher
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "dimension" && type === "change") {
        setValue("quantity", 0);
      }
      return () => subscription.unsubscribe();
    });
  }, [watch, selectedProduct]);

  useEffect(() => {
    const [quantity, dimension] = watchChange;

    if (typeof dimension === "string" && typeof quantity === "number") {
      const [width, height] = dimension.split("x");
      const surface = (quantity * (width * height)) / 1000000;
      setValue("surface", surface);
    }
  }, [watchChange]);

  const handleAddToOffer = (selectedPrice) => {
    const { dimension, surface, quantity } = getValues();

    if (!selectedPrice.value || !dimension || !surface || !quantity) return;

    const data = {
      id: `${selectedProduct._id}_${dimension}`,
      data: { ...selectedProduct },
      dimension,
      surface,
      quantity,
      price: selectedPrice.value,
      totalPrice: surface * selectedPrice.value,
    };

    if (!isEmpty(selectedProduct)) {
      addToOffer({
        ...data,
      });
      setProduct({});
      setSelectedPrice({});
    }
  };

  const onQRScan = (data) => {
    const parsedData = JSON.parse(data);

    const { glassId, quantity, sheetWidth, sheetHeight } = parsedData;
    const selectedProduct = products?.find(
      ({ product }) => product._id === glassId
    )?.product;

    if (!selectedProduct) return toast.error("Proizvod nije pronađen");
    console.log(selectedProduct);

    const surface =
      (parseInt(quantity) * parseInt(sheetWidth) * parseInt(sheetHeight)) /
      1000000;

    addToOffer({
      id: `${glassId}_${sheetWidth}x${sheetHeight}`,
      data: selectedProduct,
      dimension: `${sheetWidth}x${sheetHeight}`,
      surface,
      quantity: parseInt(quantity),
      totalPrice: surface * selectedProduct.price.regularPrice,
    });
  };

  return (
    <Wrapper>
      <Distributor col="3-3-1-1-1-3-1" align="end">
        <FormField>
          <Label htmlFor="product">Proizvod</Label>
          <DynamicInput />
        </FormField>
        <FormField>
          <Label htmlFor="dimension">Dimenzija</Label>
          <Select {...register("dimension")}>
            {selectedProduct.attributes?.dimensions.map((dimension, i) => (
              <option
                key={i}
                value={`${dimension.sheetWidth}x${dimension.sheetHeight}`}
              >{`${dimension.sheetWidth}x${dimension.sheetHeight}  -- Stanje: ${
                dimension?.stockQuantity || 0
              } kom.`}</option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label htmlFor="quantity">Količina</Label>
          <Input
            {...register("quantity", { valueAsNumber: true })}
            type="number"
            min="0"
            step="1"
          />
        </FormField>
        <FormField>
          <Label htmlFor="regularPrice">Cijena palete</Label>
          <Input
            {...register("casePrice")}
            type="number"
            readOnly
            onClick={() =>
              handleAddToOffer({
                field: "casePrice",
                value: getValues("casePrice"),
              })
            }
            selectedField={selectedPrice.field}
          />
        </FormField>
        <FormField>
          <Label htmlFor="regularPrice">Cijena ploče</Label>
          <Input
            {...register("regularPrice")}
            type="number"
            readOnly
            onClick={() =>
              handleAddToOffer({
                field: "regularPrice",
                value: getValues("regularPrice"),
              })
            }
            selectedField={selectedPrice.field}
          />
        </FormField>
        <FormField>
          <Label htmlFor="surface">Kvadratura</Label>
          <Input
            {...register("surface", { valueAsNumber: true })}
            type="number"
            readOnly
          />
        </FormField>
        {/* <FormField>
          <Button
            color="hollow"
            icon={FaPlus}
            onClick={handleAddToOffer}
            layout="round"
            width="100%"
          />
        </FormField> */}
        <FormField>
          <Button
            color="hollow"
            icon={FaQrcode}
            onClick={() => handleModal(<QRScan onScan={onQRScan} />)}
            layout="round"
            width="100%"
          />
        </FormField>
      </Distributor>
    </Wrapper>
  );
};

export default DynamicRow;
