import React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Select = React.forwardRef(
  ({ label, children, loading, ...otherProps }, ref) => {
    return (
      <Wrapper>
        <select {...otherProps} ref={ref}>
          <option value="">
            {loading ? "Učitavam..." : label ? label : "Odaberi..."}
          </option>
          {!loading &&
            children &&
            (Array.isArray(children) ? (
              children.map((child) => (
                <option key={child.props.value} {...child.props}>
                  {child.props.children}
                </option>
              ))
            ) : (
              <option key={children.props.value} {...children.props}>
                {children.props.children}
              </option>
            ))}
        </select>
      </Wrapper>
    );
  }
);

export default Select;
