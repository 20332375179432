import React from "react";
import { FaPlus } from "react-icons/fa";
import styled from "styled-components";
import Button from "../../components/elements/form/Button/Button";
import Tabs, { Tab } from "../../components/elements/Tabs/Tabs";
import TopControls from "../../components/structure/TopControls/TopControls";
import OffersTable from "./Offers/OffersTable";

const Wrapper = styled.div``;

const Offers = ({ history, location }) => {
  const { pathname } = location;

  return (
    <Wrapper>
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          icon={FaPlus}
          onClick={() => history.push(`${pathname}/new`)}
        >
          Napravi ponudu
        </Button>
      </TopControls>
      <Tabs>
        <Tab title="Ponude">
          <OffersTable />
        </Tab>
        <Tab title="Ponude od gostiju">
          <OffersTable guestsOnly />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default Offers;
