import React from "react";
import styled from "styled-components";
import Tabs, { Tab } from "../../components/elements/Tabs/Tabs";
import ProductCategoriesTable from "./Products/ProductCategoriesTable";
import ProductsTable from "./Products/ProductsTable";

const Wrapper = styled.div``;

const Products = () => {
  return (
    <Wrapper>
      <Tabs container="lg">
        <Tab title="Svi proizvodi">
          <ProductsTable />
        </Tab>
        <Tab title="Kategorije proizvoda">
          <ProductCategoriesTable />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default Products;
