import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CHANGE_PASSWORD from "../../../apollo/mutations/changePassword";
import Button from "../../elements/form/Button/Button";
import FormField from "../../elements/form/FormField/FormField";
import FormHeader from "../../elements/form/FormHeader/FormHeader";
import Input from "../../elements/form/Input/Input";
import Label from "../../elements/form/Label/Label";
import Distributor from "../../structure/Distributor/Distributor";
import { changePasswordSchema } from "../schema";

const ChangePasswordSection = ({ userId }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  console.log("USERID", userId);

  const [changePasswordMutation, { loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: () => {
      toast.success("Password izmijenjen");
      reset();
    },
    onError: () =>
      toast.error("Greška pri izmjeni passworda, password nije izmijenjen"),
  });

  const onChangePwHandler = ({ newPassword }) => {
    changePasswordMutation({
      variables: {
        userId,
        newPassword,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onChangePwHandler)}>
      <FormHeader>Izmjena passworda</FormHeader>
      <Distributor col="2-2-1" align="end">
        <FormField>
          <Label htmlFor="password" error={errors.password?.message}>
            Novi password
          </Label>
          <Input {...register("newPassword")} />
        </FormField>
        <FormField>
          <Label
            htmlFor="repeatNewPassword"
            error={errors.repeatNewPassword?.message}
          >
            Potvrdi password
          </Label>
          <Input {...register("repeatNewPassword")} />
        </FormField>
        <FormField>
          <Button layout="round" width="100%" loading={loading}>
            Izmijeni password
          </Button>
        </FormField>
      </Distributor>
    </form>
  );
};

export default ChangePasswordSection;
