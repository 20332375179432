import React, { useContext, useEffect } from "react";
import { MdClose } from "react-icons/md";
import styled, { keyframes } from "styled-components";
import { ModalContext } from "../../../context/modalContext";

const bounceFromBottom = keyframes`
  0% {
    transform: translateY(100vh);
  }
  50% {
    transform: translateY(-30px)
  }
  100% {
    transform: translateY(0)
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgb(17 84 133 / 66%);
  animation: ${fadeIn} 0.2s ease;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.albino};
  color: ${({ theme }) => theme.colors.grey};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0 3px;
  color: #9a9a9a;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Body = styled.div`
  padding: 2rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  animation: ${bounceFromBottom} 0.3s ease;
  position: relative;
  max-width: 80%;
  min-width: 500px;
  @media screen and (max-width: 600px) {
    width: 95%;
    min-width: 0;
    max-width: 95%;
  }
`;

const Content = styled.div``;

const Modal = () => {
  const { modalContent, handleModal, modal } = useContext(ModalContext);

  useEffect(() => {
    modal
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [modal]);

  return modal ? (
    <Wrapper>
      <Body>
        <CloseButton onClick={handleModal}>
          <MdClose size="20px" />
        </CloseButton>
        <Content>{modalContent}</Content>
      </Body>
    </Wrapper>
  ) : null;
};

export default Modal;
