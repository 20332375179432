import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { FaCaretLeft } from "react-icons/fa";
import styled from "styled-components";
import GET_USER_PRICES from "../../../apollo/queries/getUserPrices";
import GET_USERS from "../../../apollo/queries/getUsers";
import Button from "../../../components/elements/form/Button/Button";
import FormField from "../../../components/elements/form/FormField/FormField";
import FormHeader from "../../../components/elements/form/FormHeader/FormHeader";
import Label from "../../../components/elements/form/Label/Label";
import Select from "../../../components/elements/form/Select/Select";
import IconText from "../../../components/elements/IconText/IconText";
import Container from "../../../components/structure/Container/Container";
import Distributor from "../../../components/structure/Distributor/Distributor";
import { Context as OfferContext } from "../../../context/offerContext";
import NewOfferTable from "./NewOfferTable";

const Wrapper = styled.div``;

const NewOffer = ({ history }) => {
  const { register, getValues } = useForm();
  const { data: { getUsers } = {}, loading } = useQuery(GET_USERS);
  const { createOffer } = useContext(OfferContext);

  const [
    getUserPrices,
    { data: { getUserPrices: priceData } = {}, loading: loadingPrices },
  ] = useLazyQuery(GET_USER_PRICES, {
    onCompleted: ({ getUserPrices }) => {
      createOffer(getUserPrices[0].user._id);
    },
  });

  const handleChooseUser = () => {
    const userId = getValues("username");
    if (userId) {
      getUserPrices({
        variables: {
          userId,
        },
      });
    }
  };

  return (
    <Wrapper>
      <IconText
        onClick={history.goBack}
        text="Idi nazad"
        icon={FaCaretLeft}
        hoverable
      />
      <Container size="xl">
        <FormHeader>Nova ponuda</FormHeader>
        {!priceData?.length ? (
          <Distributor col="5-1" align="end">
            <FormField>
              <Label>Odaberi korisnika</Label>
              <Select {...register("username")} loading={loading}>
                {getUsers?.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.username}
                  </option>
                ))}
              </Select>
            </FormField>
            <FormField>
              <Button
                onClick={handleChooseUser}
                loading={loadingPrices}
                layout="round"
              >
                Odaberi
              </Button>
            </FormField>
          </Distributor>
        ) : (
          <NewOfferTable data={priceData} />
        )}
      </Container>
    </Wrapper>
  );
};

export default NewOffer;
