export const price = (value) => {
  if (!value) return;
  return value.toLocaleString("de", {
    style: "currency",
    currency: "BAM",
    minimumFractionDigits: 2,
  });
};

export const priceWithoutCurrency = (value) => {
  if (!value) return;
  return value.toLocaleString("bs-BA", {
    minimumFractionDigits: 2,
  });
};

export const orderNumber = (value) => {
  let zeroNumbers = 4 - value.toString().length;
  return zeroNumbers <= 0 ? value : "0".repeat(zeroNumbers) + value;
};
