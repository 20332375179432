import React from "react";
import styled from "styled-components";
import Tabs, { Tab } from "../../components/elements/Tabs/Tabs";
import OrdersTable from "./Orders/OrdersTable";

const Wrapper = styled.div``;

const Orders = () => {
  return (
    <Wrapper>
      <Tabs>
        <Tab title="Narudžbe">
          <OrdersTable />
        </Tab>
        <Tab title="Narudžbe od gostiju">
          <OrdersTable guestsOnly />
        </Tab>
        <Tab title="Isporučene narudžbe">
          <OrdersTable sentToBuyerOnly />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default Orders;
