import * as QR from "qrcode.react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Button from "../../../components/elements/form/Button/Button";
import FormField from "../../../components/elements/form/FormField/FormField";
import Input from "../../../components/elements/form/Input/Input";
import Label from "../../../components/elements/form/Label/Label";
import Distributor from "../../../components/structure/Distributor/Distributor";

const Wrapper = styled.div``;
const QRWrapper = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const QRCode = ({ data }) => {
  const { register, getValues } = useForm();
  const [quantity, setQuantity] = useState(0);
  console.log(data);

  const handleQuantity = () => {
    setQuantity(getValues("quantity"));
  };

  //TODO : Switch to PNG, and make able to print?

  return (
    <Wrapper>
      <QRWrapper>
        <QR
          value={JSON.stringify({ ...data, quantity })}
          height={"100%"}
          width={"100%"}
          renderAs="svg"
        />
      </QRWrapper>
      <Distributor align="end" col="2-1">
        <FormField>
          <Label htmlFor="quantity">Količina</Label>
          <Input {...register("quantity")} />
        </FormField>
        <FormField>
          <Button onClick={handleQuantity}>Generiši kod</Button>
        </FormField>
      </Distributor>
    </Wrapper>
  );
};

export default QRCode;
