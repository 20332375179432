import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Cart from "../components/elements/Cart/Cart";
import AdminLayout from "../components/structure/Layout/AdminLayout";
import Layout from "../components/structure/Layout/Layout";
import { Provider as CurrentUserProvider } from "../context/currentUser";
import { ModalProvider } from "../context/modalContext";
import { Provider as OfferProvider } from "../context/offerContext";
import Home from "../pages/Home.page";
import Login from "../pages/Login.page";
import Order from "../pages/Order.page";
import AdminRoutes from "./AdminRoutes";

const AppRouter = () => {
  return (
    <Router>
      <CurrentUserProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route>
            <ModalProvider>
              <Cart />
              <Layout>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/order" component={Order} />
                  <Route path="/admin">
                    <AdminLayout>
                      <OfferProvider>
                        <AdminRoutes />
                      </OfferProvider>
                    </AdminLayout>
                  </Route>
                </Switch>
              </Layout>
            </ModalProvider>
          </Route>
        </Switch>
      </CurrentUserProvider>
    </Router>
  );
};

export default AppRouter;
