import gql from "graphql-tag";

const SAVE_PRICE_LIST = gql`
  mutation savePriceList(
    $name: String
    $priceListId: ID
    $prices: [PriceInput!]!
  ) {
    savePriceList(name: $name, priceListId: $priceListId, prices: $prices)
  }
`;

export default SAVE_PRICE_LIST;
