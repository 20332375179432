import React from "react";
import styled from "styled-components";
import Container from "../../structure/Container/Container";
import SearchInput from "./SearchInput";

const Wrapper = styled.div``;

const SectionWrapper = styled.div`
  padding: 20px 0 50px 0;
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
`;

const Search = () => {
  return (
    <Wrapper>
      <SectionWrapper>
        <Container size="lg">
          <h1>Pretraga</h1>
        </Container>
      </SectionWrapper>
      <Container size="lg">
        <SearchInput />
      </Container>
    </Wrapper>
  );
};

export default Search;
