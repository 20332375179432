import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import TopControls from "../../../components/structure/TopControls/TopControls";
import Button from "../../../components/elements/form/Button/Button";
import { FaPlus } from "react-icons/fa";
import { ModalContext } from "../../../context/modalContext";
import GET_PRODUCT_CATEGORIES from "../../../apollo/queries/getProductCategories";
import CreateProductCategory from "../../../components/forms/CreateProductCategory.form";

const ProductCategoriesTable = () => {
  const { handleModal } = useContext(ModalContext);

  const handleAddProductCategory = () => {
    handleModal(<CreateProductCategory />);
  };

  const {
    loading,
    data: { getProductCategories } = {},
    error,
  } = useQuery(GET_PRODUCT_CATEGORIES);

  return (
    <>
      <TopControls>
        <Button
          color="hollow"
          predefinedSize="small"
          layout="round"
          icon={FaPlus}
          onClick={handleAddProductCategory}
        >
          Dodaj kategoriju proizvoda
        </Button>
      </TopControls>
      <Table loading={loading}>
        <TableHead>
          <TableRow>
            <TableHeader>Slika kategorije</TableHeader>
            <TableHeader>Naziv kategorije</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getProductCategories?.map((item) => (
            <TableRow key={item._id}>
              <TableCell>
                {item.image ? (
                  <img
                    src={process.env.REACT_APP_AWS_BUCKET + item.image}
                    alt={item.name}
                  />
                ) : (
                  "Nema slike"
                )}
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={item.id}
                  actions={[
                    {
                      label: "Uredi kategoriju",
                      action: () =>
                        handleModal(
                          <CreateProductCategory
                            populateFields={item}
                            categoryId={item._id}
                          />
                        ),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ProductCategoriesTable;
