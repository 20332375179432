import gql from "graphql-tag";

const GET_PRODUCTS = gql`
  query getProducts {
    getProducts {
      _id
      sortingIndex
      name
      inventoryTag
      price {
        regularPrice
        casePrice
      }
      category {
        name
        _id
      }
      attributes {
        thickness
        dimensions {
          sheetWidth
          sheetHeight
        }
      }
    }
  }
`;

export default GET_PRODUCTS;
