import gql from "graphql-tag";

const GET_USER = gql`
  query getUser($userId: ID!) {
    getUser(userId: $userId) {
      _id
      username
      fullName
      company
      role
      contactInfo {
        phoneNumber
        email
        address {
          location
          geo_lon
          geo_lat
        }
      }
      creditLimit {
        approvedLimit
        paymentTerm
        debt
      }
      dateCreated
    }
  }
`;

export default GET_USER;
