import createDataContext from "./createDataContext";

const globalReducer = (state, action) => {
  switch (action.type) {
    case "update_notifications":
      return { ...state, unreadNotifications: action.payload };
    default:
      return state;
  }
};

const updateNotifications = (dispatch) => (unreadCount) => {
  dispatch({ type: "update_notifications", payload: unreadCount });
};

export const { Context, Provider } = createDataContext(
  globalReducer,
  {
    updateNotifications,
  },
  { unreadNotifications: 0 }
);
