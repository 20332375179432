import React, { useContext } from "react";
import Button from "../../../components/elements/form/Button/Button";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/Table/Table";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown";
import { ModalContext } from "../../../context/modalContext";
import Space from "../../../components/structure/Space/Space";
import FormField from "../../../components/elements/form/FormField/FormField";
import QRCode from "./QRCode";
import CreateDimension from "../../../components/forms/CreateDimension.form";
import { AiOutlineWarning } from "react-icons/ai";
import { useMutation } from "@apollo/client";
import REMOVE_DIMENSION from "../../../apollo/mutations/removeDimension";
import { toast } from "react-toastify";

const DimensionsTable = ({ data }) => {
  const { handleModal } = useContext(ModalContext);
  const [removeDimensionMutation] = useMutation(REMOVE_DIMENSION, {
    onCompleted: () => toast.success("Dimenzija uklonjena"),
    onError: () => toast.error("Dimenzija nije uklonjena"),
  });

  const handleAddDimension = () => {
    handleModal(<CreateDimension productId={data._id} />);
  };

  const handleRemoveDimension = (dimensionId) => {
    if (
      prompt(
        "Unesite POTVRDA za brisanje dimenzije. Ova operacija je ireverzibilna i neće biti moguće nadomjestiti podatke"
      ) === "POTVRDA"
    ) {
      removeDimensionMutation({
        variables: {
          productId: data._id,
          dimensionId,
        },
        refetchQueries: ["getProduct"],
      });
    }
  };

  return (
    <>
      {data.attributes?.dimensions?.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>Dužina</TableHeader>
              <TableHeader>Visina</TableHeader>
              <TableHeader>Količina</TableHeader>
              <TableHeader>Granica za dopunu lagera</TableHeader>
              <ActionHeader></ActionHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.attributes.dimensions.map((dimension) => (
              <TableRow key={dimension._id}>
                <TableCell>{dimension.sheetWidth}</TableCell>
                <TableCell>{dimension.sheetHeight}</TableCell>
                <TableCell>{dimension.stockQuantity}</TableCell>
                <TableCell>{dimension.lowStockLevelIndicator}</TableCell>
                <ActionCell>
                  <ButtonDropdown
                    id={dimension._id}
                    actions={[
                      {
                        label: "Uredi dimenziju",
                        action: () =>
                          handleModal(
                            <CreateDimension
                              productId={data._id}
                              populateFields={dimension}
                            />
                          ),
                      },
                      {
                        label: "Generiši QR kod",
                        action: () =>
                          handleModal(
                            <QRCode
                              data={{
                                dimensionId: dimension._id,
                                sheetWidth: dimension.sheetWidth,
                                sheetHeight: dimension.sheetHeight,
                                glassId: data._id,
                              }}
                            />
                          ),
                      },
                      {
                        label: "Obriši dimenziju",
                        icon: <AiOutlineWarning />,
                        action: () => handleRemoveDimension(dimension._id),
                      },
                    ]}
                  />
                </ActionCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        "Nema unesenih dimenzija"
      )}
      <Space vertical="3rem" />
      <FormField>
        <Button layout="round" color="secondary" onClick={handleAddDimension}>
          Dodaj dimenziju
        </Button>
      </FormField>
    </>
  );
};

export default DimensionsTable;
