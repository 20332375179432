import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CREATE_GUEST_ORDER from "../../apollo/mutations/createGuestOrder";
import { Context as CartContext } from "../../context/cartContext";
import { ModalContext } from "../../context/modalContext";
import Button from "../elements/form/Button/Button";
import FormField from "../elements/form/FormField/FormField";
import FormHeader from "../elements/form/FormHeader/FormHeader";
import Input from "../elements/form/Input/Input";
import Label from "../elements/form/Label/Label";
import Textarea from "../elements/form/Textarea/Textarea";
import Distributor from "../structure/Distributor/Distributor";
import { sendGuestOrderSchema } from "./schema";

const OrderForm = ({ data }) => {
  const { clearCart } = useContext(CartContext);

  const { handleModal } = useContext(ModalContext);
  const [createGuestOrderMutation, { loading }] = useMutation(
    CREATE_GUEST_ORDER,
    {
      onCompleted: () => {
        toast.success("Narudžba poslana, očekujte da vas kontaktiramo ubrzo");
        handleModal();
        clearCart();
      },
      onError: () =>
        toast.error(
          "Greška u slanju narudžbe, kontaktirajte nas preko kontakt forme"
        ),
    }
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendGuestOrderSchema),
  });

  const handleSendGuestOrder = (values) => {
    if (!data?.length) return;

    const cart = data.map((cartItem) => ({
      product: cartItem.data._id,
      dimension: cartItem.dimension,
      quantity: parseFloat(cartItem.quantity),
    }));

    createGuestOrderMutation({
      variables: {
        orderData: {
          user: values.user,
          cart,
          note: values?.note,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleSendGuestOrder)}>
      <FormHeader>Pošalji narudžbu</FormHeader>
      <FormField>
        <Label htmlFor="fullName" error={errors.user?.fullName?.message}>
          Ime i prezime
        </Label>
        <Input {...register("user.fullName")} />
      </FormField>
      <FormField>
        <Label htmlFor="phoneNumber" error={errors.user?.phoneNumber?.message}>
          Broj telefona
        </Label>
        <Input {...register("user.phoneNumber")} />
      </FormField>
      <FormField>
        <Label htmlFor="note" error={errors.note?.message}>
          Napomena
        </Label>
        <Textarea {...register("note")} rows="5"></Textarea>
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button layout="round" loading={loading}>
          Pošalji narudžbu
        </Button>
      </Distributor>
    </form>
  );
};

export default OrderForm;
