import React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Textarea = React.forwardRef(({ icon, counter, ...otherProps }, ref) => {
  return (
    <Wrapper>
      <textarea {...otherProps} ref={ref} />
    </Wrapper>
  );
});

export default Textarea;
