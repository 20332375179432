import { useEffect } from "react";

const useDetectOutsideClick = (ref, outsideClickHandler) => {
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        outsideClickHandler();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref]);
};

export default useDetectOutsideClick;
