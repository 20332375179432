import { useMutation } from "@apollo/client";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import styled from "styled-components";
import SIGN_URL from "../../../apollo/mutations/signUrl";
import { generateFilename } from "../../../helpers/fileHelpers";
import SelectedFiles from "./SelectedFiles";

const Wrapper = styled.div`
  ${({ multi }) =>
    !multi &&
    `
    > div:last-child {
      display: flex;
      flex: 1;
      > div {
        height: 200px;
      }
    }
  `};
`;

const FilesDropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${({ theme }) => theme.colors.lighterGrey};
  border-radius: 5px;
  margin-bottom: 1rem;
  :hover {
    cursor: pointer;
  }
`;

const UploadProgress = styled.div`
  height: 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.blue};
  width: ${({ progress }) => progress}%;
  margin-bottom: 1rem;
`;

const UploadFiles = ({
  multi,
  onSelect,
  files,
  onFileUpload,
  uploadFolder,
  uploaded,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const [signUrlMutation, { loading }] = useMutation(SIGN_URL, {
    onCompleted: ({ signUrl }) => {
      console.log(signUrl);
      signUrl?.map(async (file) => {
        // upload file to s3

        const fileObject = files.find((f) => f.name === file.originalFilename);
        // axios options
        const options = {
          onUploadProgress: ({ loaded, total }) => {
            // TODO: progress doesn't work
            setUploadProgress(Math.round((loaded / total) * 100));
          },
          headers: {
            "Content-Type": fileObject.type,
          },
        };

        try {
          await axios.put(file.signedRequest, fileObject, options);
          onFileUpload(file.filename);
        } catch (e) {
          console.log(e);
          toast.error("Nemoguće uploadati fajlove");
        }
      });
      // set data to modal
    },
    onError: (error) => {
      toast.error("Nemoguće uploadati fajlove");
      console.log(error);
    },
  });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploadProgress(0);
      if (!uploadFolder)
        return toast.error(
          "Nije naveden folder za upload, kontaktirajte administratora"
        );

      const filesArray = Array.isArray(files)
        ? acceptedFiles.map((file) => ({
            filename: generateFilename(uploadFolder),
            filetype: file.type,
            originalFilename: file.name,
          }))
        : [acceptedFiles].map((file) => ({
            filename: generateFilename(uploadFolder),
            filetype: file.type,
            originalFilename: file.name,
          }));

      if (multi) {
        onSelect(acceptedFiles);
      } else {
        onSelect([acceptedFiles[0]]);
      }

      signUrlMutation({
        variables: {
          files: filesArray,
        },
      });
    },
    [multi, files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemove = (index) => {
    // remove file from array on click
    onSelect(() => {
      if (uploaded.length) {
        uploaded.filter((_, i) => i !== index);
      }
      files.filter((_, i) => i !== index);
    });
    // TODO: Remove file from s3
  };

  return (
    <Wrapper multi={multi}>
      <FilesDropzone {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Pustite fajlove ovdje</p>
        ) : (
          <p>Prevucite fajlove mišem ili kliknite za odabir</p>
        )}
      </FilesDropzone>
      <UploadProgress progress={uploadProgress} />
      {files?.length || uploaded?.length ? (
        <SelectedFiles
          files={files}
          onRemove={handleRemove}
          uploaded={uploaded}
        />
      ) : null}
    </Wrapper>
  );
};

export default UploadFiles;
