import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import client from "./apollo/client";
import { Provider as CartProvider } from "./context/cartContext";
import { Provider as GlobalProvider } from "./context/globalContext";
import AppRouter from "./routes/AppRouter";
import GlobalStyles from "./theme/global";
import theme from "./theme/theme";

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <CartProvider>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <GlobalProvider>
            <AppRouter />
          </GlobalProvider>
        </CartProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
