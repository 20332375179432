import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { FaBell } from "react-icons/fa";
import styled from "styled-components";
import MARK_AS_SEEN from "../../apollo/mutations/markAsSeen";
import GET_NOTIFICATIONS from "../../apollo/queries/getNotifications";
import ButtonDropdown from "../../components/elements/ButtonDropdown/ButtonDropdown";
import Table, {
  ActionCell,
  ActionHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/elements/Table/Table";
import {
  NOTIFICATION_ENTITIES,
  NOTIFICATION_SUBTYPES,
  NOTIFICATION_TYPES,
} from "../../constants/constants";
import { Context as GlobalContext } from "../../context/globalContext";

const Wrapper = styled.div``;
const IconWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  svg {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const Notifications = () => {
  const {
    updateNotifications,
    state: { unreadNotifications },
  } = useContext(GlobalContext);
  const { data: { getNotifications } = {}, loading } = useQuery(
    GET_NOTIFICATIONS,
    {
      onCompleted: ({ getNotifications: data }) => {
        const unread = data.reduce((acc, curr) => !curr.seen && acc + 1, 0);
        if (unread > 0) updateNotifications(unread);
      },
    }
  );

  const [markAsSeenMutation] = useMutation(MARK_AS_SEEN, {
    onCompleted: () => updateNotifications(unreadNotifications - 1),
  });

  const handleMarkAsSeen = (notificationId) => {
    markAsSeenMutation({
      variables: { notificationId },
      refetchQueries: ["getNotifications"],
    });
  };

  return (
    <Wrapper>
      <Table loading={loading}>
        <TableHead>
          <TableRow>
            <TableHeader></TableHeader>
            <TableHeader>Važnost</TableHeader>
            <TableHeader>Upućuje na</TableHeader>
            <TableHeader>Entitet</TableHeader>
            <TableHeader>Poruka</TableHeader>
            <ActionHeader></ActionHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getNotifications?.map((notification) => (
            <TableRow key={notification._id}>
              <TableCell>
                {!notification.seen ? (
                  <IconWrapper>
                    <FaBell />
                  </IconWrapper>
                ) : null}
              </TableCell>
              <TableCell>{NOTIFICATION_TYPES[notification.type]}</TableCell>
              <TableCell>
                {NOTIFICATION_SUBTYPES[notification.subType]}
              </TableCell>
              <TableCell>
                {NOTIFICATION_ENTITIES[notification.affectedEntity]}
              </TableCell>
              <TableCell>{notification.message}</TableCell>
              <ActionCell>
                <ButtonDropdown
                  id={notification.id}
                  actions={[
                    {
                      label: "Označi kao pročitanu",
                      action: () => handleMarkAsSeen(notification._id),
                    },
                    {
                      label: "Ukloni notifikaciju",
                      action: () => alert("Removing..."),
                    },
                  ]}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default Notifications;
